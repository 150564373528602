.task-groups-list {
  $this: &;
  height: 100%;
  display: flex;
  flex-direction: column;

  &_empty {
    background-color: var(--color-background);
    position: relative;
  }

  &__spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__spinner-item {
    position: relative;
    bottom: 6px;
    .ant-spin-dot {
      font-size: 30px;
    }
    .ant-spin-dot-item {
      width: 12px;
      height: 12px;
    }
  }

  &__header {
    display: flex;
    align-items: center;
  }

  &__dates {
    color: var(--color-text-main);
    font-weight: 500;
    margin-right: 0;
  }

  &__name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__name-mark {
    color: var(--color-text-secondary);
    padding-left: 5px;
    font-weight: normal;
  }

  &__header-top {
    display: flex;
    align-items: flex-start;
  }

  &__name-tooltip {
    max-width: 300px;
    width: 100%;
  }

  &__header-top-left {
    display: flex;
    align-items: center;
    overflow: hidden;
    margin-right: 10px;
    flex-grow: 1;
  }

  &__header {
    flex: 0 0 64px;
    background-color: var(--color-background);
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    padding: 16px;
    position: relative;

    h2 {
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      margin-bottom: 0;
      color: var(--color-text-main);
    }

    &__toggle {
      font-size: 20px;
      position: relative;
      top: -2px;
    }
  }

  &__header-toolbar {
    color: var(--color-text-main);
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  &__header-toolbar-button {
    .ant-btn {
      padding: 4px 8px;
      background-color: var(--color-background);
      color: var(--color-text-link);
      border-color: var(--color-text-link);
      &:hover {
        color: #fff;
        background-color: var(--color-text-link);
      }
    }
    &:not(:last-child) {
      margin-right: 5px;
    }
  }

  &__body {
    flex: 1 1 0;
    overflow: auto;
    height: 100%;
    background: var(--color-background-task-group);
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  &__group {
    background-color: var(--color-background);
    &.is-collapsed {
      #{$this}__group-header {
        height: 40px;
      }
    }

    &_backlog {
      #{$this}__header-epic-right {
        justify-content: flex-start;
      }
      #{$this}__group-header {
        .epic-stats-bar {
          margin-left: 30px;
          @media screen and (max-width: 1300px) {
            margin-left: 15px;
          }
        }
      }
    }

    &.has-dates {
      #{$this}__group-header {
        height: 100px;
      }
      &.is-collapsed {
        #{$this}__group-header {
          height: 60px;
        }
      }
    }
  }

  &__header-epic-right {
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-shrink: 0;
    width: 260px;
    @media screen and (max-width: 1300px) {
      width: 150px;
    }
    .time-tracker-control {
      &_fixed {
        margin-left: auto;
      }
    }
    .task-groups-list {
      &__action-buttons {
        margin-left: 10px;
      }
    }
  }

  &__group-header {
    padding: 9px 13px;
    position: relative;
    height: 80px;
    overflow: hidden;
    &_epic {
      padding-left: 13px;
      padding-right: 13px;
    }

    h3 {
      font-size: 14px;
      line-height: 22px;
      color: var(--color-text-main);
      font-weight: 600;
      margin-bottom: 0;
      margin-right: 5px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .epic-stats-bar {
      margin-left: 15px;
      margin-top: 3px;
      width: 150px;
      overflow: hidden;
    }
  }

  &__group-header-top {
    display: flex;
    align-items: center;
  }

  &__group-header-toggler {
    color: var(--color-text-main);
    font-size: 10px;
    margin-right: 5px;
    align-self: flex-start;
    margin-top: 7px;
  }

  &__list-name {
    margin-right: 5px;
    display: inline-block;
  }

  &__drag-wrap {
    position: absolute;
    top: 9px;
    left: 3px;
  }

  &__sprint-status {
    margin-top: 2px;
    border-radius: 4px;
    font-size: 12px;
  }

  &__header-sprint-right {
    display: flex;
    align-items: center;
    margin-left: auto;
    .task-groups-list {
      &__action-buttons {
        width: auto;
      }
    }

    .tracker-control {
      line-height: 1;
    }
    .time-tracker-control .anticon-pause-circle {
      top: 0;
    }
  }

  &__action-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: 5px;
    position: relative;
    margin-right: -5px;
    width: 75px;
    flex-shrink: 0;
    .bp3-popover-wrapper,
    .bp3-popover-target {
      display: block;
    }
  }

  &__group-body {
    &_empty {
      margin-top: -1px;
    }
    .task-list-item {
      height: 63px;
      &__body {
        max-width: 100%;
        height: 63px;
      }
      &__title {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        a {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }

  .task-group-dropdown-title {
    text-transform: capitalize;
    border-radius: 4px;
    font-size: 13px;
    margin-right: 5px;
  }
}

.quick-add-task {
  margin: 8px 0;
  background-color: var(--color-background);
  .quick-add-task-input {
    background-color: var(--color-background);
    color: var(--color-text-main);
    border-radius: 2px;
    height: 24px;
    padding-left: 8px;
    padding-right: 8px;
    &.focused {
      border-color: #40a9ff;
      outline: 0;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }
    .ProseMirror {
      min-height: 0;
      height: 100%;
      padding-top: 3px;
      overflow: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      border: none;
      padding-left: 0;
      padding-right: 0;

      &::-webkit-scrollbar {
        display: none;
      }
      p {
        font-size: 12px;
        white-space: nowrap;
      }
    }
  }
}

.quick-add-task-input {
  display: block;
}

.epic-stats-bar {
  display: flex;
  justify-content: flex-start;

  &__step {
    flex: 1 1 0;
    max-width: 24px;
    min-width: 1px;
    display: inline-block;
    height: 6px;
    margin: 0 0.5px;
  }
}
