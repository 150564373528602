.project-page-filter-target {
  display: block;
  width: 16px;
  margin-right: 3px;
  position: relative;
  top: 1px;
  cursor: pointer;
  svg {
    transition-property: fill;
    transition-duration: 0.3s;
    fill: var(--color-text-main);
  }
  &:hover,
  &.is-active {
    svg {
      fill: var(--color-text-link);
    }
  }
}
