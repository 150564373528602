.task-panel-drop-zone {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  &__inner {
    height: 100%;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__group {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__title {
    color: #ffffff;
    font-size: 20px;
  }

  &__area {
    position: relative;
    border-radius: 2px;
    height: 200px;
    border: 3px dashed #54a0ff;
    transition: background-color 0.3s;
  }

  &__hint {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    color: #ffffff;
  }
}
