.error-page-layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  &__message {
    margin-bottom: 15px;
  }

  &__content {
    text-align: center;
  }
}
