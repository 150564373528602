.project-page-filter-portal {
  .bp3-transition-container {
    max-width: 250px;
    width: 100%;
  }
  .bp3-popover {
    width: 100%;
    max-height: 600px;
    background-color: #ffffff;
  }
}
