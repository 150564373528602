.internal-link-node-view-wrapper {
  border: 1px solid rgba(0, 0, 0, 0.075);
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}
