.add-time-manually-modal {
  background-color: var(--color-background-popover);

  &__body {
    padding: 20px;
  }

  .fc-scrollgrid-section-header {
    .fc-scroller {
      overflow: hidden !important;
    }
  }

  .fc {
    .fc-event-main {
      .bp3-popover-wrapper {
        min-height: 1px;
      }

      .bp3-popover-wrapper,
      .bp3-popover-target {
        height: 100%;
      }

      .bp3-popover-target {
        > div {
          height: 100%;
        }
      }
    }
    .fc-event-main-frame {
      display: flex;
      flex-direction: row;
    }
    .fc-event-title {
      position: static;
      margin-left: 5px;
      font-size: 12px;
    }
    .fc-event-time {
      font-size: 12px;
      &::after {
        display: none;
      }
    }
  }

  &__buttons {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
  }
  &__button {
    &:not(:last-child) {
      margin-right: 12px;
    }
  }
  &__cancel-button {
    color: var(--color-text-main);
    background: transparent !important;
  }
  &__sections {
    display: flex;
    > div {
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
  &__calendar {
    height: 400px;
    margin-bottom: 15px;

    > .fc {
      padding: 0;
    }
  }
}
