.filters-list-wrap {
  position: relative;
  max-width: 0;
  flex-shrink: 0;
  width: 100%;
  overflow: hidden;
  transition: max-width 0.4s;
  background-color: var(--color-background);
  &.is-expanded {
    max-width: 234px;
    overflow: visible;
    .filters-list {
      opacity: 1;
      transition-delay: 0.15s;
    }
  }
  .filters-list {
    opacity: 0;
    background-color: var(--color-background);
  }
}

// .filters-list {
//   position: relative;
//   max-width: 234px;
//   width: 100%;
//   height: 100%;
//   overflow: hidden;
//   transition: max-width 0.4s;
//   background-color: #fafafa;
//   &.is-expanded {
//     max-width: 233px;
//     overflow: visible;
//     .filters-list {
//       opacity: 1;
//       transition-delay: 0.1s;
//     }
//   }
//   .filters-list {
//     opacity: 0;
//   }
// }

.filters-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 27px 16px;
  transition: opacity 0.2s;

  &__title {
    margin-bottom: 12px;
    font-style: normal;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 14px;
    line-height: 1;
    color: #595959;
  }

  &__item {
    position: relative;
    &:not(:last-child) {
      margin-bottom: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid var(--color-border);
    }

    &_users {
      border-bottom: 0;
      overflow: scroll;
    }

    &_status {
      display: flex;
      flex-direction: column;
    }

    &__date-range {
      overflow: hidden;
    }
  }

  &__checkbox {
    line-height: 1;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

.clear-filters {
  margin-top: auto;
}
