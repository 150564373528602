.task-groups-details-info-date {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  &__title {
    color: var(--color-text-inactive);
    font-size: 12px;
    font-weight: 500;
    margin-right: 10px;
    text-transform: uppercase;
    min-width: 68px;
  }
  &__content {
    color: var(--color-text-secondary);
    font-size: 16px;
    & .avatar-img {
      margin: -5px 5px 0 0;
    }
  }
}
