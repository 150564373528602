.change-user-password-dialog {
  &.bp3-dialog {
    max-width: 400px;
    width: 100%;
    padding: 20px 10px;
    background-color: #ffffff;
  }
  &__title {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 600;
    color: #4a4a4a;
  }
  &__input-group {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__submit-button {
    display: block;
    max-width: 150px;
    width: 100%;
    padding: 5px 16px;
    margin-left: auto;
    margin-right: auto;
    background-color: var(--color-text-link);
    color: #ffffff;
    font-size: 14px;
    border-radius: 2px;
    border: none;
    cursor: pointer;
  }
  &__form {
    width: 80%;
  }
}

.change-user-password-dialog-input-group {
  display: flex;
  flex-direction: column;
  &__label {
    display: inline-block;
    font-weight: 600;
    margin-bottom: 3px;
    color: #4a4a4a;
  }
  &__input-wrap {
    position: relative;
  }
  &__input {
    display: block;
    width: 100%;
    padding: 4px 30px 4px 8px;
    border: 1px solid #e8e8e8;
    border-radius: 2px;
    outline: none;
    font-size: 14px;
    transition-property: border-color;
    transition-duration: 0.2s;
    &::placeholder {
      color: var(--color-text-inactive);
      transition-property: opacity;
      transition-duration: 0.2s;
    }
    &:focus {
      border-color: var(--color-text-link);
      &::placeholder {
        opacity: 0;
      }
    }
  }
  &__show {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    display: block;
    width: 16px;
    height: 16px;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
    outline: none;
    line-height: 1;
  }
}
