.sort-menu {
  .bp3-popover-content {
    // min-width: 100px;
    overflow: hidden;
  }
  &.bp3-popover {
    &.bp3-minimal {
      margin-top: 0 !important;
    }
  }
}

.sort-menu-content {
  &__item {
    display: flex;
    align-items: center;
    height: 30px;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
  }
}

