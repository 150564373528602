.project-page-editor {
  .ProseMirror {
    min-height: 100px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 8px;
    border: 1px solid transparent;
    border-radius: 2px;
    font-size: 12px;
  }
}
