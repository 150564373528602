.project-select {
  $this: &;
  max-width: 233px;
  position: relative;

  .bp3-input-action {
    pointer-events: none;
  }

  .bp3-menu {
    list-style: none;
    margin: 0;
  }

  .bp3-select-popover {
    width: 100%;
  }

  .bp3-input {
    font-size: 13px;
    border: 1px solid var(--color-border);
    box-shadow: none;
    color: var(--color-text-main);

    &::placeholder {
      color: var(--color-text-inactive);
    }
  }

  .bp3-input:focus,
  .bp3-input.bp3-active {
    box-shadow: none;
    border: 1px solid var(--color-text-link);
  }

  .bp3-transition-container {
    margin-top: 5px;
    width: 100%;
  }

  &__list-content {
    width: 100%;
  }

  &__list-abbr {
    color: var(--color-text-secondary);
  }

  &__list-full {
    color: var(--color-text-main);
    font-size: 13px;
  }
}

.multi-select-popover {
  .bp3-multi {
    .project-select__list-abbr,
    .project-select__list-full,
    .project-select__list-content {
      color: #ffffff;
    }
  }
}

.project-select-popover {
  width: 210px;
}
