.user-field {
  &_date {
    .bp3-input {
      height: 22px;
    }

    .bp3-icon {
      margin: 5px 0 0 0;

      svg {
        width: 14px;
        height: 14px;
      }
    }

    .bp3-input-group {
      .bp3-input:not(:first-child) {
        padding-left: 20px;
      }
    }
  }

  .bp3-input-group {
    &.bp3-disabled,
    .bp3-input:disabled,
    .bp3-input.bp3-disabled {
      cursor: default;
    }

    .bp3-input:disabled,
    .bp3-input.bp3-disabled {
      background-color: transparent;
    }
  }

  .bp3-editable-text {
    &::before {
      display: none;
    }

    &:hover {
      &::before {
        display: none;
      }
    }
  }

  .bp3-button.bp3-minimal:disabled,
  .bp3-button.bp3-minimal:disabled:hover,
  .bp3-button.bp3-minimal.bp3-disabled,
  .bp3-button.bp3-minimal.bp3-disabled:hover {
    color: var(--color-text-main);
    cursor: default;
  }

  .bp3-editable-text,
  .bp3-input,
  .bp3-button,
  &__text {
    border: 0;
    color: var(--color-text-main);
    font-size: 14px;
    box-shadow: none;
    padding-left: 0;

    &::placeholder,
    .bp3-text-muted {
      color: var(--color-text-inactive);
      font-size: 14px;
    }
  }

  .bp3-editable-text-placeholder {
    .bp3-editable-text-content {
      color: var(--color-text-inactive);
      font-size: 14px;
    }

    .bp3-editable-text-input {
      &::placeholder {
        color: var(--color-text-inactive);
      }

      box-shadow: none;

      border: none;
    }
  }

  &_timezone {
    .bp3-button {
      padding: 0;
      border: 0;
      outline: none;
      box-shadow: none;
      background-color: transparent;
      min-height: 24px;

      .bp3-icon {
        display: none;
      }

      .bp3-button-text {
        margin-right: 0;
      }

      &:hover {
        border: 0;
        box-shadow: none;
        background-color: transparent;
      }
    }
  }
}
