.task-group-details {
  $this: &;
  max-width: 1000px;
  width: 100%;
  padding: 16px;
  background-color: var(--color-background);
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-radius: 3px;

  &__switcher {
    margin-bottom: 20px;
    &.is-checked {
      #{$this}__switcher-label {
        color: var(--color-text-link);
      }
    }
  }

  &__switcher-label {
    font-weight: 500;
    text-transform: uppercase;
    margin-right: 5px;
    color: var(--color-text-secondary);
    font-size: 13px;
    transition: color 0.2s;
  }

  &__section {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
  &__content {
    flex-grow: 1;
  }

  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  &__group-top {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .bp3-popover-wrapper {
      line-height: 1;
    }
  }

  &__header-left {
    margin-right: 10px;
    flex-grow: 1;
    svg {
      width: 14px;
    }
  }

  &__header-right {
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }

  &__title-wrap {
    display: flex;
    align-items: flex-start;
  }

  &__title {
    flex-grow: 1;
  }

  &__menu {
    color: var(--color-text-main);
    flex-shrink: 0;
  }

  &__group-type {
    text-transform: uppercase;
    position: relative;
    font-size: 14px;
    font-weight: 600;
    color: var(--color-text-inactive);
    padding-right: 8px;
    margin-right: 8px;
    &::before {
      content: '';
      position: absolute;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      background-color: var(--color-background-divider);
      width: 1px;
      height: 12px;
    }
  }

  &__status {
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 10px;
    color: var(--color-text-secondary);
  }
}

.task-groups-switcher-select {
  &__target {
    color: var(--color-text-main);
    cursor: pointer;
  }
}
