.add-time-manually-comment {
  textarea {
    background-color: var(--color-background);
    display: block;
    width: 100%;
    min-height: 80px;
    padding: 5px;
    resize: none;
  }
}
