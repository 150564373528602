.prose-mirror-image-container {
  position: relative;
  width: 100%;
}

.prose-mirror-image-container img {
  position: relative;
  width: 100%;
}

.prose-mirror-image-container .prose-mirror-image-close-buttons {
  display: none;
}

.prose-mirror-image-container:hover .prose-mirror-image-close-buttons {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
}
