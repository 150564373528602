.panel-section-title {
  position: relative;
  display: flex;
  align-items: center;
  color: var(--color-text-inactive);
  padding-bottom: 8px;
  &__text {
    padding-right: 8px;
    flex-shrink: 0;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
  }

  * {
    color: var(--color-text-inactive);
  }

  .task-panel {
    &__section-title {
      font-weight: 600;
      color: var(--color-text-inactive);
    }
  }

  .small-filter-button {
    border-color: var(--color-text-inactive);
    transition: border-color 0.3s;

    &:hover,
    &.no-background-active {
      border-color: var(--color-text-link);
      svg {
        fill: var(--color-text-link);
      }
    }
  }

  &__line {
    flex-grow: 1;
    border: 1px solid var(--color-background-section-line);
  }
}
