.tracker-comment-control {
  position: relative;
  background-color: var(--color-background-popover);

  &__close-icon {
    position: relative;
    top: 1px;
    margin-left: auto;
    margin-right: -10px;
    cursor: pointer;
  }

  &__title {
    display: flex;
    align-items: center;
    min-width: 177px;
    min-height: 32px;
    margin: 0;
    padding: 5px 16px 4px;
    color: var(--color-text-main);
    font-weight: 500;
    border-bottom: 1px solid var(--color-border-input);
  }

  &__title-text {
    margin-right: auto;
    margin-left: auto;
  }

  &__form {
    &.ant-form {
      display: flex;
      flex-direction: column;
      padding: 12px;
    }
  }

  &__textarea {
    &.ant-input {
      resize: none;
      width: 100%;
      min-height: 80px;
      margin-bottom: 10px;
    }
  }

  &__submit {
    align-self: flex-start;
  }
}

.tracker-comment-popover {
  &.ant-popover {
    min-width: 240px;
  }
  .ant-popover-inner-content {
    padding: 0;
  }
}
