.editor-footer {
  border-top: 0;
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
  align-items: center;
  transition: border-color 0.3s;
  padding-bottom: 5px;
  &:focus {
    outline: none;
  }

  &__left {
    display: flex;
    align-items: center;
  }

  &__action-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    padding: 2px 5px;
    border-radius: 5px;
    overflow: hidden;
    transition-property: background-color;
    transition-duration: 0.3s;
    cursor: pointer;
    outline: none;
    border: none;
    background-color: transparent;
    flex-shrink: 0;
    font-size: 15px;

    &:hover {
      background-color: #ecf0f1;
    }
  }
  &__submit-button {
    display: inline-block;
    margin-left: 10px;
    padding: 5px 16px;
    border: none;
    text-align: center;
    cursor: pointer;
    color: #ffffff;
    background-color: var(--color-text-link);
    border-radius: 2px;
    &:focus {
      outline: none;
    }
    &[disabled] {
      cursor: default;
      pointer-events: none;
      background-color: #9b9b9b;
    }
  }
}
