.project-details {
  max-width: 1000px;
  width: 100%;
  height: 100%;
  background-color: var(--color-background);
  overflow: auto;
  border-radius: 3px;
  .custom-scrollbar-content {
    padding: 16px;
  }
  .project-details {
    &__section {
      &.project-details-activities {
        margin-bottom: 5px;
      }
    }
  }
  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 12px;
  }
  &__header-left-top {
    display: inline-flex;
    align-items: center;
    margin-bottom: 10px;
  }
  &__header-left {
    margin-right: 10px;
    width: calc(80% - 10px);
  }
  &__header-right {
    flex-shrink: 0;
  }
  &__title {
    position: relative;
    text-transform: uppercase;
    color: var(--color-text-inactive);
    font-size: 14px;
    font-weight: 600;
    padding-right: 8px;
    margin-right: 8px;
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      width: 1px;
      height: 12px;
      background-color: #e8e8e8;
    }
  }
  &__status {
    position: relative;
    color: var(--color-text-secondary);
    font-weight: 500;
    text-transform: uppercase;
    font-size: 11px;
    padding-right: 12px;
    cursor: pointer;
    margin-bottom: 1px;
    &_active {
      &::before {
        background-color: #52c41a;
      }
    }
    &_closed {
      &::before {
        background-color: var(--color-text-secondary);
      }
    }
    &::before {
      content: '';
      position: absolute;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }
  }

  &__updated-label {
    text-transform: uppercase;
    color: var(--color-text-inactive);
    font-size: 10px;
    font-weight: 500;
  }

  &__updated-date {
    vertical-align: middle;
    color: var(--color-text-secondary);
    font-weight: 400;
    font-size: 12px;
  }

  &__main-info {
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;
  }

  &__project-logo {
    flex-shrink: 0;
    &.avatar-uploader {
      width: 40px;
      height: 40px;
      margin-right: 8px;
      &.ant-upload-picture-card-wrapper {
        max-width: 100%;
        width: 40px;
        .ant-upload-text {
          font-size: 10px;
        }
      }
    }
  }

  &__project-name {
    display: flex;
    align-items: flex-start;
    font-size: 18px;
    font-weight: 400;
    width: 100%;
    min-width: 0;
    margin-top: 6px;
    color: var(--color-text-main);
  }

  &__project-name-line {
    color: #e8e8e8;
    margin-left: 3px;
    margin-right: 3px;
  }

  &__name-textarea {
    min-width: 30px;
    margin-bottom: 2px;
    margin-top: -3px;
    width: 100%;
    &:focus {
      outline: none;
    }
  }

  &__section {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  &__data {
    margin-bottom: 24px;
  }
}
