.editor-toolbar {
  display: inline-flex;
  background-color: rgba(#34495e, 0.8);
  padding: 5px;
  border-radius: 5px;

  &__tooltip {
    &.bp3-tooltip {
      .bp3-popover-content {
        padding: 2px 8px;
      }
    }
  }
  > span {
    &:not(:last-child) {
      margin-right: 5px;
    }
  }
  &__item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    padding: 2px 5px;
    border-radius: 5px;
    transition-property: background-color, color;
    transition-duration: 0.3s;
    cursor: pointer;
    outline: none;
    flex-shrink: 0;
    font-size: 13px;
    color: #ffffff;

    img {
      display: block;
      max-width: 13px;
      width: 100%;
    }
    &_html {
      img {
        max-width: 16px;
      }
    }
    &:hover,
    &.is-active {
      background-color: #ecf0f1;
      color: rgba(#34495e, 0.8);
    }
  }
}
