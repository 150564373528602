@import '~prosemirror-tables/style/tables.css';
@import '~prosemirror-gapcursor/style/gapcursor.css';

.ProseMirror {
  table {
    margin: 0;
  }

  th,
  td {
    min-width: 1em;
    border: 1px solid #ddd;
    padding: 3px 5px;
    padding-top: 12px;
    padding-left: 12px;
    padding-right: 12px;
  }

  p.empty-node {
    position: relative;
    &::before {
      white-space: nowrap;
      max-width: 100%;
      text-overflow: ellipsis;
      // overflow: hidden;
    }
  }

  th {
    font-weight: bold;
    text-align: left;
    background-color: rgba(195, 203, 211, 0.2);
  }
}
