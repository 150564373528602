.task-activity {
  $this: &;
  font-size: 12px;
  color: var(--color-text-task-activity);

  .activity-page-editor {
    &.prose-mirror-editor {
      border: none;
    }
  }

  &__delete-button {
    margin-left: 5px;
    opacity: 0;
    transition: opacity 0.3s;
  }

  &__bold-text {
    font-weight: 600;
  }

  &__date {
    margin-left: 2px;
  }

  &__changed-time-interval {
    letter-spacing: -0.5px;
  }

  &__action {
    color: var(--color-text-link);
  }

  &__action-title {
    color: var(--color-text-link);
  }

  &__highlighted {
    color: var(--color-text-link);
  }

  &__text-bold {
    font-weight: 600;
  }

  &__italic-text-log {
    color: var(--color-text-secondary);
    font-style: italic;
  }

  &__text-uppercase {
    text-transform: uppercase;
  }

  &__details {
    margin-left: 10px;
    padding-left: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-left: 1px solid var(--color-text-inactive);
    font-size: 12px;
  }

  &__details-button {
    margin-left: 5px;
    font-size: 12px;
    color: var(--color-text-link);
    cursor: pointer;
    &_no-margin {
      margin-left: 0;
    }
    &_is-rotated {
      #{$this}__details-button-icon {
        transform: rotate(180deg);
      }
    }
  }

  &__details-button-icon {
    transition: transform 0.3s;
    vertical-align: middle;
  }
}

.worklog-container {
  position: relative;
  display: flex;
  align-items: center;
  &_has-popover,
  &:hover {
    .task-activity {
      &__delete-button {
        opacity: 1;
      }
    }
  }
}
