.task-user-portal {
  .bp3-transition-container {
    max-width: 225px;
    width: 100%;
  }
}

.user-popover {
  width: 100%;
  .bp3-input-group {
    display: block;
    border: 1px solid var(--color-text-link);
    border-radius: 2px;
    margin: 4px;
    &:focus {
      box-shadow: none;
      outline: none;
    }
    input {
      &:focus {
        box-shadow: none;
        outline: none;
      }
    }
  }
  .bp3-input {
    padding-right: 25px !important;
    box-shadow: none;
    font-size: 14px;
    max-width: 215px;
    &::placeholder {
      color: var(--color-text-inactive);
    }
  }

  .bp3-input-action {
    top: 44%;
    right: 5px;
    transform: translateY(-50%);
    &:last-child {
      right: 10px;
    }
  }
  &.popover {
    .bp3-popover-content {
      .bp3-menu {
        max-width: 225px;
        width: 100%;
      }
    }
  }
}

.user-select {
  $this: &;
  .bp3-popover,
  .bp3-transition-container,
  .bp3-menu {
    max-width: 130px;
  }

  &_full {
    #{$this}__full-name {
      display: block;
    }
  }

  &__list-item {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__list-avatar {
    flex-shrink: 0;
    margin-right: 8px;
  }

  &__list-full-name {
    max-width: 160px;
    width: 100%;
  }

  .bp3-menu-item {
    font-size: 12px;
    overflow: hidden;
  }

  .bp3-button {
    &#{$this}__button {
      padding: 0;

      .bp3-button-text {
        display: block;
      }

      &_disabled {
        cursor: not-allowed;
      }
    }
  }

  &__content {
    display: flex;
    align-items: center;
  }

  &__full-name {
    max-width: 160px;
    width: 100%;
    margin-right: 5px;
    padding: 1px 0;
    font-size: 13px;
    @media screen and (max-width: 1499px) {
      display: none;
    }
  }

  &__avatar {
    flex-shrink: 0;
    &_unassigned {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 22px;
      height: 22px;
      color: #ffffff;
      background-color: var(--color-text-inactive);
      border-radius: 2px;
    }
  }
  .user-initials {
    position: relative;
    bottom: 1px;
  }
}
