.task-panel-editor {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  &_disabled {
    cursor: not-allowed;
  }
  .ProseMirror {
    border-bottom: 0;
  }
  .editor-footer__action-button {
    background-color: var(--color-background);
    color: var(--color-text-main);
  }
  .editor-footer__action-button:hover {
    background-color: var(--color-background-selected);
  }
  // .editor-footer {
  //   padding-left: 5px;
  //   padding-right: 5px;
  //   left: 20px;
  //   right: 20px;
  //   bottom: 6px;
  //   border-color: transparent;
  // }
}
