$normalIndent: 15px;

.login-form {
  background-color: var(--color-background-popover);
  margin: 150px auto;
  max-width: 400px;
  width: 100%;

  padding: $normalIndent $normalIndent 0;
  border: 1px solid var(--color-border-input);

  .ant-form-item {
    margin-bottom: $normalIndent;
  }

  h3 {
    margin: 0;
  }
  .login-form-btn {
    text-align: center;
  }
}
