.task-list {
  border-top: 1px solid var(--color-border);
}

.task-list-item {
  display: flex;
  align-items: stretch;
  width: 100%;
  border-bottom: 1px solid var(--color-border);
  background-color: var(--color-background);
  position: relative;

  .task-list-item-skeleton {
    animation: none;
    &_time {
      margin-right: 8px;
    }
  }

  &__title-bricks {
    position: relative;
    top: -1px;
    display: inline-flex;
    align-items: center;
  }

  &__time-warning {
    position: relative;
    color: #fff;
    font-size: 14px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    background-color: #f5222d;
    border-radius: 2px;
    margin-right: 5px;
  }

  &__title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    a {
      position: relative;
      top: 1px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__title-blocked {
    display: flex;
    margin-right: 3px;
  }

  &__body {
    max-width: 100%;
    height: 63px;
  }

  &__remove-linked-task {
    position: relative;
    flex-shrink: 0;
    max-width: 6px;
    overflow: hidden;
    width: 100%;
    border: 0;
    padding: 0;
    background-color: var(--color-text-link);
    transition: max-width 0.3s;
    &:focus {
      outline: none;
    }
    cursor: pointer;
    .bp3-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: opacity 0.3s;
      opacity: 0;
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      box-shadow: inset -3px 0 5px -1px rgba(0, 0, 0, 0.17);
      opacity: 0;
      transition: opacity 0.3s;
    }
    &:hover {
      max-width: 37px;
      &::before {
        opacity: 1;
      }
      .bp3-icon {
        opacity: 1;
      }
    }
  }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__body {
    position: relative;
    padding: 8px 16px;
    flex-grow: 1;
    padding-left: 14px;
    align-self: center;
  }

  &__handle {
    position: absolute;
    z-index: 10;
    top: 8px;
    left: 0;
    width: 16px;
    text-align: center;
    color: var(--color-text-inactive);
  }

  &__properties-select {
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    min-height: 16px;
    margin-right: 5px;
    .priority-select {
      &__button {
        min-height: 0;
      }
    }
  }

  &__size-select {
    display: inline-block;
    vertical-align: middle;
    margin-right: 9px;
    line-height: 1;
  }

  &__properties {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-left {
      display: flex;
      align-items: center;
      margin-right: 10px;
      flex-grow: 1;
    }

    &-right {
      text-align: right;
      display: flex;
      align-items: center;

      .user-select {
        &__button {
          min-width: 0;
          min-height: 0;
        }
      }
    }
  }

  &__user-select {
    margin-right: 8px;
  }

  &__code {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    // top: 1px;
    flex-shrink: 0;
    font-size: 12px;
    line-height: 1.2;
    color: #595959;
    font-weight: 600;
    letter-spacing: -0.7px;
  }

  &__group {
    position: relative;
    max-width: 150px;
    min-width: 60px;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 600;
    color: var(--color-text-secondary);
    font-size: 12px;
    line-height: 1.2;
    margin-left: 8px;
    padding-left: 8px;
    letter-spacing: -0.3px;
    display: inline-block;
    vertical-align: middle;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 1px;
      height: 12px;
      background-color: var(--color-background-divider);
    }
  }

  &__title {
    a {
      font-size: 14px;
      line-height: 1.2;
      color: var(--color-text-main);
    }
  }

  &__copy-link-icon {
    transition: opacity 0s;
    opacity: 0;
  }

  &:hover &__copy-link-icon {
    opacity: 1;
    color: var(--color-text-inactive);
    margin-left: 5px;
    cursor: pointer;
    transition: color 0s;

    * {
      transition: color 0s;
    }

    &:active {
      color: var(--color-text-link);
    }
  }

  &_highlighted {
    background: var(--color-background-selected);
  }
}
