.users-page {
  display: flex;
  height: 100%;
  background: #313e4d;

  &__users-list {
    max-width: 300px;
    width: 100%;
    height: 100%;
    background-color: var(--color-background);
    // box-shadow: -1px 2px 5px -2px #888;
  }

  &__user-details {
    min-height: 600px;
    width: 100%;
    //padding: 16px;
    overflow: auto;
  }

  &__user-card {
    max-width: 820px;
    width: 100%;
    overflow: hidden;
  }

  .users-list-container {
    height: 100%;
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    background-color: white;
  }

  .bp3-editable-text-content {
    text-overflow: clip;
  }
}
