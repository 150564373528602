.suggest {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 233px;
  width: 100%;
  height: 100%;
  &__name {
    font-size: 13px;
  }

  .custom-scrollbar-track_y {
    width: 5px;
    right: 2px;
  }

  .user-initials {
    position: relative;
    bottom: 1px;
  }

  &__empty-list-label {
    color: var(--color-text-secondary);
    text-align: center;
    padding: 7px;
  }

  &__search-icon {
    display: block;
  }

  .bp3-input-group {
    .bp3-input-action {
      top: 50%;
      right: 9px;
      transform: translateY(calc(-50% - 3px));
    }
  }

  .bp3-input {
    font-size: 13px;
    color: var(--color-text-inactive);
    box-shadow: none;
    border: 1px solid var(--color-border);
    border-radius: 2px;
    margin-bottom: 5px;
    &:focus,
    &.bp3-active {
      color: var(--color-text-main);
    }

    &::placeholder {
      color: var(--color-text-inactive);
    }
  }

  &__tiles {
    overflow-y: auto;
  }

  &__tile {
    &.project-tile {
      height: auto;
      display: flex;
      align-items: center;
      padding: 2px 6px 2px 2px;
    }

    .bp3-tag-remove {
      outline: none;
      box-shadow: none;
    }

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  &__content {
    display: flex;
    align-items: center;
  }

  &__avatar {
    &.ant-avatar {
      margin-right: 6px;
      flex-shrink: 0;
    }
  }
}

.suggest-portal {
  .bp3-transition-container {
    max-width: 202px;
    width: 100%;
    z-index: 1001;
  }
}

.suggest-popover {
  width: 100%;
  .bp3-menu {
    max-height: 250px;
  }
}

.project-tile {
  &.task-types {
    &__tile {
      min-height: 34px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
