.sidebar-logo-img {
  display: block;
  max-width: 100%;
  width: 28px;
  height: 28px;
  transform: rotate(180deg);
}

.sidebar-logo-wrapper {
  overflow: hidden;
  padding: 0 26px;
  &.is-expanded {
    padding: 0 20px;
  }
}
