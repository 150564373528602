.header {
  display: flex;
  align-items: center;
  height: 64px;
  padding: 16px;
  background-color: var(--color-background);

  .project-page-filter-target {
    top: 3px;
    font-size: 15px;
  }

  &__title-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    line-height: 1;
    margin-right: 20px;
    width: calc(30% - 20px);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    flex-shrink: 0;
    @media screen and (max-width: 1100px) {
      width: calc(25% - 20px);
    }
    &_full {
      width: 100%;
      @media screen and (max-width: 1100px) {
        width: 100%;
      }
    }
  }

  &__title {
    line-height: 1.2;
    color: inherit;
    min-width: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: var(--color-text-main);
    font-size: 30px;
    font-weight: 600;
    margin-right: 5px;
    &:hover {
      color: var(--color-text-main);
    }
    @media screen and (max-width: 1400px) {
      font-size: 24px;
    }
    @media screen and (max-width: 1100px) {
      font-size: 20px;
    }
  }

  &__title-tip {
    margin-top: 5px;
    margin-left: 3px;
    font-size: 14px;
  }

  &__search {
    align-self: center;
    flex-shrink: 0;
    position: relative;
  }

  &__right {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    flex-shrink: 1;
    flex-grow: 1;
    .time-tracker-control {
      .anticon-pause-circle {
        top: 0;
      }
    }
  }
}
