.prose-mirror-editor {
  position: relative;
  word-wrap: break-word;
  white-space: pre-wrap;
  -webkit-font-variant-ligatures: none;
  font-variant-ligatures: none;
  color: var(--color-text-main);
  * {
    color: var(--color-text-main);
  }
  a {
    color: var(--color-text-link);

    * {
      color: var(--color-text-link);
    }
  }

  border: 1px solid var(--color-border);
  .ProseMirror-hideselection *::selection {
    background: transparent;
  }
  .ProseMirror-hideselection *::-moz-selection {
    background: transparent;
  }
  .ProseMirror-hideselection {
    caret-color: transparent;
  }

  .ProseMirror-gapcursor {
    display: block;
    pointer-events: none;
    position: absolute;
  }

  .prose-mirror-image-container {
    margin-bottom: 10px;
  }

  .ProseMirror-gapcursor:after {
    content: '';
    display: block;
    position: absolute;
    top: -2px;
    width: 1px;
    height: 14px;
    border-top: 0;
    border-left: 1px solid black;
    animation: ProseMirror-cursor-blink 1.1s steps(2, start) infinite;
  }

  @keyframes ProseMirror-cursor-blink {
    to {
      visibility: hidden;
    }
  }

  .ProseMirror-focused .ProseMirror-gapcursor {
    display: block;
  }

  .ProseMirror-selectednode {
    outline: 1px solid blue;
    outline-offset: 3px;
  }

  .tasktype-node-view-wrapper {
    &.ProseMirror-selectednode {
      outline: none;
    }
  }

  .tasktype-node-view-react-component {
    display: inline-block;
  }

  .tasktype-node-view-wrapper {
    .ProseMirror-selectednode {
      outline: none;
    }
  }

  // > * {
  //   &:not(:first-child):not(:last-child):not(.ProseMirror) {
  //     display: none;
  //   }
  // }

  pre {
    white-space: pre-wrap;
  }

  code {
    display: inline-block;
    background-color: var(--color-background-code);
    padding: 5px;
  }

  .ProseMirror {
    outline: none;
    transition: border-color 0.3s;
    &.file-load {
      .empty-node {
        &::before {
          display: none;
        }
      }
    }
    p,
    pre,
    blockquote,
    code {
      font-size: 14px;
    }
    blockquote {
      margin-left: 0;
      margin-right: 0;
      padding-left: 1em;
      border-left: 3px solid #eee;
    }
    p,
    pre {
      margin-bottom: 0;
      line-height: 1.4;
    }
    h1 {
      font-size: 2em;
    }
    .empty-node {
      margin-bottom: 0;
      font-size: 14px;
      &::before {
        font-style: normal;
        color: var(--color-text-inactive);
        transition-property: opacity;
        transition-duration: 0.3s;
        // height: auto;
        // overflow: visible;
      }
    }
  }
}
