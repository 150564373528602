.avatar-uploader {
  &.ant-upload-picture-card-wrapper {
    display: block;
    max-width: 100px;
    width: 100%;

    overflow: hidden;

    .ant-upload-select-picture-card {
      width: 100%;
      height: 100%;
      float: none;
      position: relative;
      display: flex;
    }
  }

  .ant-upload-text {
    font-size: 12px;
    color: #69c0ff;
  }

  .ant-upload-select {
    border: 1px dashed #69c0ff;
    border-radius: 2px;
    background: none;
    margin: 0;

    &.ant-upload {
      >.ant-upload {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        border: none;
        padding: 0;
      }
    }
  }

  &_no-border {
    .ant-upload-select {
      border: none;
    }
  }
}
