.task-panel-comment-form {
  $this: &;
  position: relative;
  width: 100%;
  margin-top: auto;
  flex-shrink: 0;
  transition-property: transform;
  transition-duration: 0.3s;
  will-change: filter;
  -webkit-filter: drop-shadow(0 -1px 5px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0 -1px 5px rgba(0, 0, 0, 0.2));
  &_visible {
    #{$this}__button-icon {
      transform: translate(-50%, -50%) rotate(-180deg);
    }
  }

  &__header {
    top: 0;
    left: 0;
    width: 100%;
    transform: translateY(-100%);
    border-radius: 3px 3px 0 0;
    background-color: var(--color-background-popover);
  }

  &__button {
    position: absolute;
    top: 0;
    right: 8px;
    transform: translateY(-100%);
    z-index: 1;
    display: block;
    width: 30px;
    height: 26px;
    font-size: 16px;
    background-color: var(--color-background-popover);
    border: none;
    border-radius: 3px 3px 0 0;
    outline: none;
    cursor: pointer;
  }

  &__users-follow {
    display: inline-flex;
    align-items: center;
    position: absolute;
    top: 1px;
    left: 15px;
    height: 30px;
    border-radius: 3px 3px 0 0;
    padding: 0 6px;
    transform: translateY(-100%);
    background-color: var(--color-background-popover);
  }

  &__button-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition-property: transform;
    transition-duration: 0.3s;
  }

  &__content {
    min-height: 80px;
    padding: 20px;
    background-color: var(--color-background-popover);
  }

  &__no-follow-button {
    display: inline-block;
    padding: 4px 6px;
    text-align: center;
    border: 1px solid #91d5ff;
    cursor: pointer;
    color: var(--color-text-link);
    background-color: transparent;
    border-radius: 2px;
    line-height: 1.2;
    &[disabled] {
      cursor: default;
      pointer-events: none;
      background-color: #9b9b9b;
    }
  }

  &__follow-section-left {
    display: flex;
    align-items: center;
  }

  &__following-users {
    display: flex;
    align-items: center;
  }

  &__following-user {
    &:not(:last-child) {
      margin-right: 4px;
    }
    &_author {
      position: relative;
      padding-right: 4px;
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-43%);
        right: 0;
        width: 1px;
        height: 15px;
        background-color: var(--color-text-secondary);
      }
    }
  }

  &__edit-action {
    display: block;
    margin-left: 4px;
    &_follow {
      cursor: pointer;
      background: none;
      outline: none;
      span {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      width: 22px;
      height: 22px;
      border-radius: 2px;
      color: var(--color-text-link);
      font-size: 12px;
      border: 1px solid var(--color-text-link);
      margin-top: 2px;
      padding: 0;
      &:hover {
        background-color: var(--color-text-link);
        color: #fff;
      }
    }
  }

  .prose-mirror-editor {
    border-color: var(--color-text-placeholder);
  }
}

.edit-followers-portal {
  .bp3-popover {
    .bp3-popover-arrow-fill {
      fill: var(--color-background-popover);
    }
  }
}
