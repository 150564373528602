.task-description-template {
  &__button {
    background-color: var(--color-background);
    color: var(--color-text-link);
    border-color: var(--color-text-link);
    &:hover {
      color: #fff;
      background-color: var(--color-text-link);
      border-color: var(--color-text-link);
    }
    &:focus {
      background-color: var(--color-background);
      color: var(--color-text-link);
      border-color: var(--color-text-link);
    }
  }
}
