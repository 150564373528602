.project-tile {
  border: 1px solid #69c0ff;
  border-radius: 2px;
  font-size: 12px;
  color: var(--color-text-main);
  padding: 1px 6px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
