body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'SF Pro Display', 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  transition-property: color, background-color, border-color;
  transition-duration: 0.3s;
}

[data-theme='light'] {
  --color-text-main: #262626;
  --color-text-muted: #595959;
  --color-text-secondary: #8c8c8c;
  --color-text-inactive: #bfbfbf;
  --color-text-placeholder: #bfbfbf;
  --color-header-link: #002766;
  --color-text-link: #1890ff;
  --color-text-task-activity: #4a5566;

  --color-background: #ffffff;
  --color-background-darker: #f1f6ff;
  --color-background-hover: #f5f5f5;
  --color-background-selected: #e6f7ff;
  --color-background-popover: #ffffff;
  --color-background-popover-selected: #e6f7ff;
  --color-background-disabled: #f5f5f5;
  --color-background-task-blocker: #fff1f0;
  --color-background-task-done: #f6ffed;
  --color-background-divider: #e8e8e8;
  --color-background-code: #e8e8e8;
  --color-background-section-line: #cecece;
  --color-background-task-group: #cad2df;
  --color-background-layout: #f0f2f5;

  --color-border: #d9d9d9;
  --color-border-input: #f0f0f0;
  --color-border-project-tile: #69c0ff;

  --color-fc-state-unknown: #eeeeee;
  --color-fc-state-below-normal: #ffc6c8;
  --color-fc-state-normal: #d0f1b5;
  --color-fc-state-above-normal: #c3edf9;
  --color-fc-state-current-selection: #3788d8;

  --color-status-draft: #2f54eb;
  --color-border-status-draft: #adc6ff;

  --color-status-todo: #1890ff;
  --color-border-status-todo: #91d5ff;

  --color-status-in-progress: #722ed1;
  --color-border-status-in-progress: #d3adf7;

  --color-status-bug-bound: #f5222d;
  --color-border-status-bug-bound: #ffa39e;

  --color-status-qa: #fa8c16;
  --color-border-status-qa: #ffd591;

  --color-status-accepted: #52c41a;
  --color-border-status-accepted: #b7eb8f;

  --color-status-cancelled: #fa541c;
  --color-border-status-cancelled: #ffbb96;

  --color-status-verified: #006400;
  --color-border-status-verified: #009100;

  --color-status-on-review: #13c2c2;
  --color-border-status-on-review: #87e8de;

  --color-status-ready-for-testing: #4a9c69;
  --color-border-status-ready-for-testing: #b7eb8f;

  --color-status-on-hold: #c0c0c0;
  --color-border-status-on-hold: #a9a9a9;

  --color-selection-text: var(--color-text-main);
  --color-selection-background: #b7e1fd;
}

[data-theme='dark'] {
  --color-text-main: #c8c8c8;
  --color-text-muted: #8c8c8c;
  --color-text-secondary: #8c8c8c;
  --color-text-inactive: #606060;
  --color-text-placeholder: #606060;
  --color-header-link: #9ab3d7;
  --color-text-link: #1370c7;
  --color-text-task-activity: #8599b9;

  --color-background: #111a25;
  --color-background-darker: #1a1f2d;
  --color-background-hover: #1f2833;
  --color-background-selected: #1f2833;
  --color-background-popover: #15212f;
  --color-background-popover-selected: #263d56;
  --color-background-disabled: #1d1f27;
  --color-background-task-blocker: #251111;
  --color-background-task-done: #182511;
  --color-background-divider: #313131;
  --color-background-code: #313131;
  --color-background-section-line: #606060;
  --color-background-task-group: #415567;
  --color-background-layout: #2f3a46;

  --color-border: #2f3640;
  --color-border-input: #262c34;
  --color-border-project-tile: #27475e;

  --color-fc-state-unknown: #464646;
  --color-fc-state-below-normal: #3f1f21;
  --color-fc-state-normal: #25331a;
  --color-fc-state-above-normal: #1a3942;
  --color-fc-state-current-selection: #143f69;

  --color-status-draft: #adc6ff;
  --color-border-status-draft: #2f54eb;

  --color-status-todo: #91d5ff;
  --color-border-status-todo: #1890ff;

  --color-status-in-progress: #d3adf7;
  --color-border-status-in-progress: #722ed1;

  --color-status-bug-bound: #ffa39e;
  --color-border-status-bug-bound: #f5222d;

  --color-status-qa: #ffd591;
  --color-border-status-qa: #fa8c16;

  --color-status-accepted: #b7eb8f;
  --color-border-status-accepted: #52c41a;

  --color-status-cancelled: #ffbb96;
  --color-border-status-cancelled: #fa541c;

  --color-status-verified: #009100;
  --color-border-status-verified: #006400;

  --color-status-on-review: #87e8de;
  --color-border-status-on-review: #13c2c2;

  --color-status-ready-for-testing: #b7eb8f;
  --color-border-status-ready-for-testing: #4a9c69;

  --color-status-on-hold: #c0c0c0;
  --color-border-status-on-hold: #a9a9a9;

  --color-selection-text: var(--color-text-main);
  --color-selection-background: #0d4d8a;
}

#root {
  height: 100%;

  * {
    ::-moz-selection {
      /* Code for Firefox */
      color: var(--color-selection-text);
      background: var(--color-selection-background);
    }

    ::selection {
      color: var(--color-selection-text);
      background: var(--color-selection-background);
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import '../node_modules/@blueprintjs/icons/lib/css/blueprint-icons.css';
@import '../node_modules/@blueprintjs/core/lib/css/blueprint.css';
@import '../node_modules/@blueprintjs/datetime/lib/css/blueprint-datetime.css';
@import '../node_modules/@blueprintjs/select/lib/css/blueprint-select.css';
@import '../node_modules/@blueprintjs/timezone/lib/css/blueprint-timezone.css';

.app-layout {
  background-color: var(--color-background-layout);
}

.user-initials {
  font-size: 14px;
  color: #ffffff;
}

.react-loading-skeleton {
  background-color: var(--color-background-popover) !important;
  background-image: linear-gradient(
    90deg,
    var(--color-background-popover),
    var(--color-background-hover),
    var(--color-background-popover)
  ) !important;
}

// Flex is set so that user initials in avatar are vertically centered
.ant-avatar-string {
  display: flex;
}

.ant-picker {
  background-color: var(--color-background) !important;
  color: var(--color-text-main) !important;
  border-color: var(--color-border-input) !important;

  .ant-picker-input {
    input {
      color: var(--color-text-main) !important;

      &::placeholder {
        color: var(--color-text-placeholder) !important;
      }
    }
  }

  .ant-picker-suffix {
    color: var(--color-text-main) !important;
  }
}

.ant-picker-dropdown {
  color: var(--color-text-main) !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

  .ant-picker-panel-container {
    background-color: var(--color-background-popover) !important;

    .ant-picker-panel {
      border-color: var(--color-border-input) !important;

      .ant-picker-time-panel-column:not(:first-child) {
        border-left-color: var(--color-border-input) !important;
      }

      .ant-picker-time-panel-cell-selected > .ant-picker-time-panel-cell-inner {
        background-color: var(--color-background-popover-selected) !important;
      }

      .ant-picker-time-panel-column
        > li.ant-picker-time-panel-cell
        .ant-picker-time-panel-cell-inner {
        color: var(--color-text-main) !important;
      }

      .ant-picker-footer {
        border-top-color: var(--color-border-input) !important;
      }
    }
  }
}

.ant-popover-arrow {
  border-color: var(--color-background-popover) !important;
}

.ant-btn[disabled] {
  background-color: var(--color-background-disabled) !important;
  color: var(--color-text-inactive) !important;
  border-color: var(--color-border-input) !important;
}

.ant-btn-primary:disabled {
  background-color: var(--color-background-disabled) !important;
  color: var(--color-text-inactive) !important;
  border-color: var(--color-border-input) !important;
}

.ant-checkbox-inner {
  background-color: transparent !important;
  border-color: var(--color-text-secondary) !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--color-text-link) !important;
  border-color: var(--color-text-link) !important;
}

.ant-checkbox-wrapper {
  color: var(--color-text-main) !important;
}

span.ant-typography {
  color: var(--color-text-main) !important;
}

.ant-modal-content,
.ant-modal-header,
.ant-modal-body,
.ant-modal-footer,
.ant-modal-title {
  background-color: var(--color-background-popover) !important;
  color: var(--color-text-main) !important;
  border-color: var(--color-text-secondary) !important;
}

.ant-input-affix-wrapper {
  background-color: var(--color-background) !important;
  border-color: var(--color-border-input) !important;
}

.ant-modal-header {
  border-bottom-color: var(--color-border-input) !important;
}

.ant-modal-footer {
  border-top-color: var(--color-border-input) !important;
}

.ant-message-notice {
  color: var(--color-text-main) !important;

  .ant-message-notice-content {
    background-color: var(--color-background-popover) !important;
  }
}

.ant-input {
  color: var(--color-text-main) !important;
  border-color: var(--color-border-input) !important;
  background-color: var(--color-background) !important;
}

.ant-input-clear-icon {
  color: var(--color-text-secondary) !important;
}

.ant-form,
.ant-form-item,
.ant-form-item-label > label {
  color: var(--color-text-main) !important;
}

.ant-modal-close {
  color: var(--color-text-secondary) !important;
}

.ant-empty {
  color: var(--color-text-main) !important;
}

.ant-empty-img-default {
  width: auto;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: var(--color-background) !important;
  color: var(--color-text-main) !important;
  border-color: var(--color-border-input) !important;
}

.ant-select-arrow {
  color: var(--color-text-main) !important;
}

.ant-select-dropdown {
  background-color: var(--color-background-popover) !important;
  color: var(--color-text-main) !important;
}

.ant-select-item-option {
  color: var(--color-text-main) !important;
}

.ant-select-item-option-selected,
.ant-select-item-option-active {
  background-color: var(--color-background-popover-selected) !important;
  color: var(--color-text-main) !important;
}

.ant-divider-horizontal.ant-divider-with-text {
  border-top-color: var(--color-background-divider) !important;
}

.card {
  background-color: #ffffff;
  border-radius: 5px;
}

.text-trim-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ant-empty-img-default {
  width: auto;
}

.card-wrap {
  width: 100%;
  background-color: #313f4d;
  padding: 16px;
}

.ant-empty-img-default {
  width: auto;
}

.ant-avatar {
  justify-content: center;
  align-items: center;
}

.project-tile {
  background-color: transparent;
  border: 1px solid var(--color-border-project-tile);
  border-radius: 2px;
  font-size: 12px;
  color: var(--color-text-main);
  padding: 1px 6px;
}

.text-trim-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.pt-tag-input.pt-large .pt-input-ghost {
  font-size: 200px !important;
}

.ant-layout {
  .ant-layout-header {
    padding: 0 16px;
  }
}

.tracker-control {
  margin-right: 8px;
}

.header-link {
  color: var(--color-header-link);
  a {
    color: var(--color-header-link);
  }
}

.last-activity-list {
  background-color: var(--color-background-popover);
  color: var(--color-text-main);
  border-radius: 2px;
  border: 1px solid var(--color-border);
  width: 300px;
  max-height: 400px;
  &__inner {
    padding: 10px;
  }

  .last-activity-list__wrapper {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.bp3-dialog-header {
  background-color: var(--color-background-popover);
}

.bp3-heading {
  color: var(--color-text-main);
}

.bp3-datepicker
  .DayPicker-Day.DayPicker-Day--isToday
  .bp3-datepicker-day-wrapper {
  border: 2px solid #a7a8a8;
}

.bp3-daterangepicker .DayPicker-Day--selected-range {
  background-color: var(--color-background-selected);
  color: var(--color-text-main);
}

.bp3-datepicker {
  $this: &;
  background-color: var(--color-background-popover) !important;
  color: var(--color-text-main) !important;

  & select {
    color: var(--color-text-main) !important;
  }
}

.bp3-input {
  background-color: var(--color-background);
  color: var(--color-text-main);
  &::placeholder {
    color: var(--color-text-inactive);
  }
}

.bp3-menu {
  background-color: var(--color-background-popover);
  color: var(--color-text-main);

  .bp3-active {
    background-color: var(--color-background-popover-selected) !important;
  }

  li:hover {
    background-color: var(--color-background-hover) !important;
  }
}

.bp3-popover .bp3-popover-content {
  background-color: var(--color-background-popover);
  color: var(--color-text-main) !important;
}

.bp3-popover-arrow-fill {
  fill: var(--color-background-popover) !important;
}

::-webkit-contacts-auto-fill-button,
::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
}

.popover {
  background-color: var(--color-background-popover) !important;
}

.comment-outlined {
  transition-property: color;
  transition-duration: 0.3s;
  &:hover {
    color: var(--color-text-link);
  }
}

.bp3-icon-more {
  transition-property: color;
  transition-duration: 0.3s;
  &:hover {
    color: var(--color-text-link);
  }
}

::placeholder {
  color: var(--color-text-placeholder);
}
