.user-profile {
  $this: &;
  display: flex;
  flex-direction: column;
  max-width: 820px;
  min-height: 600px;
  padding: 32px;
  border-radius: 2px;
  background-color: var(--color-background);

  &__change-password {
    margin-top: 5px;
    text-align: right;
  }

  &_active {
    #{$this}__heading-item {
      &_status {
        &::after {
          background: #52c41a;
        }
      }
    }

    #{$this}__main-avatar {
      &.ant-avatar {
        background-color: #52c41a;
      }
    }
  }

  &_invited {
    #{$this}__heading-item {
      &_role {
        color: var(--color-text-secondary);
      }

      &_status {
        color: #fa8c16;

        &::after {
          display: none;
        }
      }
    }

    #{$this}__main-avatar {
      &.ant-avatar {
        background-color: #fa8c16;
      }
    }
  }

  &_inactive {
    #{$this}__heading-item {
      &_role {
        color: var(--color-text-secondary);
      }

      &_status {
        color: var(--color-text-secondary);

        &::after {
          background-color: var(--color-text-inactive);
        }
      }
    }

    #{$this}__main-avatar {
      filter: grayscale(100%);
    }
  }

  &__heading {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  &__main {
    display: flex;
    justify-content: space-between;
  }

  &__main-important-info {
    display: flex;
  }

  &__main-about {
    width: 100%;
    margin-bottom: -10px;

    .multi-select {
      .bp3-multi-select-tag-input-input {
        border: 0;
      }
    }

    #{$this}__info {
      margin-right: 20px;
      margin-bottom: 10px;
      max-width: 170px;
      width: 100%;

      &_full-name {
        max-width: 160px;
        width: auto;
        margin-right: 25px;
      }

      &_email {
        max-width: 270px;
        width: 100%;
        margin-right: 0;
      }

      &_projects {
        position: relative;
        width: 100%;
        max-width: 450px;
        margin-right: 0;
      }

      .bp3-popover-wrapper {
        width: 100%;
      }
    }
  }

  &__main-left {
    width: 75%;
    margin-top: 3px;
    margin-right: 32px;
  }

  &__main-info {
    display: flex;
  }

  &__main-avatar {
    width: 100px;
    height: 100px;
    margin-right: 16px;
    flex-shrink: 0;

    &.ant-avatar {
      margin-right: 16px;
    }
  }

  &__main-avatar_img {
    &.ant-avatar {
      background-color: #52c41a;
    }
  }

  &__heading-item {
    &_small {
      position: relative;
      margin-left: 7px;
      padding-left: 7px;
      font-size: 10px;
      text-transform: uppercase;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 1px;
        height: 12px;
        background: #e8e8e8;
      }
    }

    &_role {
      color: var(--color-text-link);

      .ant-select-selection-item {
        color: var(--color-text-link);
      }

      .ant-select-focused {
        &.ant-select-single:not(.ant-select-customize-input) {
          .ant-select-selector {
            color: var(--color-text-link);
          }
        }
      }
    }

    .ant-select-focused {
      &.ant-select-single:not(.ant-select-customize-input) {
        .ant-select-selector {
          box-shadow: none;
          opacity: 1;
        }
      }
    }

    .ant-select-focused {
      opacity: 1;
    }

    .ant-select-single.ant-select-open {
      .ant-select-selection-item {
        opacity: 1;
      }
    }

    .ant-select {
      font-size: 10px;
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      border: 0;
      height: auto;
      padding: 0;
    }

    .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
    .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
      padding-right: 0;
    }

    .ant-select-arrow {
      display: none;
    }

    &_status {
      color: var(--color-text-secondary);
      padding-right: 12px;

      .ant-select-focused {
        &.ant-select-single:not(.ant-select-customize-input) {
          .ant-select-selector {
            color: var(--color-text-secondary);
          }
        }
      }

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 8px;
        height: 8px;
        border-radius: 16px;
      }
    }
  }

  &__stat {
    text-align: right;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &__stat-field {
    font-size: 12px;
    color: var(--color-text-secondary);
    white-space: nowrap;
  }

  &__infos {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: -20px;

    &_details {
      flex-wrap: nowrap;
      #{$this}__info {
        margin-right: 20px;
        &_slack {
          max-width: 250px;
        }

        &_birthday {
          margin-right: 0;
        }
      }
    }

    &_private {
      max-width: 660px;
      width: 100%;

      #{$this}__info {
        max-width: 140px;
        width: 100%;
        margin-right: 20px;
      }
    }
  }

  &__info {
    margin-bottom: 20px;

    .bp3-editable-text-content {
      text-overflow: clip;
    }

    &_facebook,
    &_linkedin {
      overflow: hidden;
    }
  }

  &__info-link {
    white-space: nowrap;
  }

  &__projects {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-left: -4px;

    .bp3-tag-input
      .bp3-tag-input-values:first-child
      .bp3-input-ghost:first-child {
      padding-left: 1px;
    }
  }

  &__project {
    margin-bottom: 4px;

    &:not(:last-child) {
      margin-right: 4px;
    }
  }

  &__content {
    margin-bottom: 20px;
  }

  &__invite-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
  }

  &__invite-button {
    &_link {
      .ant-btn-link {
        margin-right: 20px;
        padding: 0;
        color: var(--color-text-secondary);
        border: 0;
        border-bottom: 1px dashed var(--color-text-secondary);

        &:hover {
          border-bottom: 1px dashed var(--color-text-secondary);
          color: var(--color-text-secondary);
        }
      }
    }
  }
}
