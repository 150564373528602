.mention-user-menu {
  max-height: 200px;
  overflow: auto;
}

.mention-user-menu.bp3-menu {
  box-shadow: 0 2px 8px -1px rgba(0, 0, 0, 0.05);
  padding: 0;
}

.mention-user-menu .bp3-menu-item.bp3-active,
.mention-user-menu .bp3-menu-item:active {
  background-color: var(--color-background-selected);
}
