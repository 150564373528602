.project-page {
  position: relative;
  display: flex;
  height: 100%;
  background: #313e4d;
  overflow: hidden;

  &__filters-list {
    position: absolute;
    z-index: 11;
    top: -1px;
    bottom: 0;
    left: 0;
    width: 240px;
    transform: translateX(-100%);
    transition: transform 0.3s;
    background-color: var(--color-background-popover);
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2),
      0 8px 24px rgba(16, 22, 26, 0.2);

    &.is-active {
      transform: translateX(0);
    }
  }

  &__header {
    .page-search-target {
      &__icon {
        right: 0;
      }
    }

    .header {
      &__search {
        top: 3px;
      }
    }
  }

  &__panel {
    flex: 1 1 30%;
    max-width: 1000px;
    min-width: 0;
    height: 100%;
    margin-left: 8px;
    padding: 8px 0;

    &:last-child {
      margin-right: 8px;
    }

    > div {
      height: 100%;
    }

    &_details {
      overflow: hidden;
    }

    &_collapsed {
      flex: 0 0 auto;
      width: 65px;
    }
  }
}

.collapsed-pane {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  background: var(--color-background);
  padding: 16px 8px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  color: var(--color-text-main);

  &__icon {
    display: block;
    font-size: 20px;
  }

  h2 {
    color: var(--color-text-main);
    transform: rotate(-90deg);
    display: inline-block;
    line-height: 1;
    position: relative;
    margin-bottom: 0;
    top: 35px;
  }
}
