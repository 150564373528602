.multi-select {
  transition: border 0.3s;
  border: 1px solid transparent;
  &.bp3-tag-input {
    background: transparent;
    box-shadow: none;
    padding-left: 0;
    cursor: default;
    padding-left: 3px;
    padding-right: 3px;
    &.bp3-active {
      border: 1px solid #40a9ff;
    }
  }

  &:hover {
    border: 1px solid #40a9ff;
  }

  .bp3-text-overflow-ellipsis {
    overflow: clip;
  }

  .bp3-multi-select-tag-input-input {
    min-height: 22px;
    font-size: 12px;
    border: 1px solid #40a9ff;
    padding-left: 5px;
    border-radius: 2px;
  }

  .bp3-tag {
    border: 1px solid #69c0ff;
    border-radius: 2px;
    font-size: 12px;
    color: var(--color-text-main);
    background: none;
    padding: 1px 6px;
  }

  &_column {
    flex-direction: column;

    .bp3-tag-input-values {
      margin-top: 0;
      margin-right: 0;
    }

    .bp3-multi-select-tag-input-input {
      order: -1;
      border-color: var(--color-border);
      background-color: var(--color-background);
      padding: 3px 5px;
    }

    .bp3-tag {
      width: 100%;
      padding: 1px 5px 1px 1px;
      background-color: #ffffff;
    }
  }

  &__user {
    display: flex;
    align-items: center;
  }

  &__avatar {
    margin-right: 4px;
  }
}

.multi-select-popover-wrap {
  display: block;
  .bp3-transition-container {
    max-width: 100% !important;
    width: 100%;
  }

  .bp3-popover-target {
    display: block;
    &:focus {
      outline: 2px solid #000;
    }
  }
}
