.task-panel-comment-form-editor {
  &.is-focused {
    .ProseMirror {
      min-height: 54px;
    }
  }
  .ProseMirror {
    max-height: 265px;
    overflow: auto;
    font-size: 12px;
    padding: 5px 5px 15px;
    min-height: 23px;
    transition-property: min-height;
    transition-duration: 0.3s;
    border-radius: 2px;
    &:focus {
      min-height: 54px;
    }
  }
  .editor-footer {
    // left: 1px;
    // right: 1px;
    // bottom: 1px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 10px;
    background-color: var(--color-background-popover);
    &:focus {
      ~ .ProseMirror {
        min-height: 54px;
      }
    }

    .editor-footer__action-button {
      color: var(--color-text-main);
    }

    .editor-footer__action-button:hover {
      background-color: var(--color-background-hover);
    }
  }
}
