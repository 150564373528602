.select {
  display: inline-block;
  line-height: 1;

  &_disabled {
    .bp3-button {
      cursor: default;
    }
  }

  .bp3-input-group {
    display: none;
  }

  &:focus {
    outline: none;
    outline-offset: 0;
  }

  .bp3-menu-item {
    color: var(--color-text-main);
    font-size: 14px;
    background-color: var(--color-background-popover);
    transition: background-color 0.3s;

    &:hover {
      background-color: var(--color-background-hover);
    }
  }

  .bp3-popover-target {
    > div {
      &:focus {
        outline: none;
        outline-offset: 0;
      }
    }

    &:focus {
      outline: none;
      outline-offset: 0;
    }
  }

  .bp3-button {
    background: none;
    box-shadow: none;

    &:hover,
    &:active,
    &:focus {
      background: none;
      box-shadow: none;
      outline: none;
    }
  }

  .bp3-popover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 2px;

    .bp3-popover-content {
      padding: 0;

      .bp3-menu {
        padding: 0;
        min-width: 42px;

        .bp3-menu-item {
          border-radius: 0;
        }

        .bp3-active {
          background-color: var(--color-background-popover-selected);
          color: var(--color-text-main);
        }
      }
    }
  }

  .bp3-transition-container,
  .bp3-popover,
  .bp3-menu {
    max-width: 89px;
  }

  .bp3-transition-container {
    margin-top: 4px;
  }
}
