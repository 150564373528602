.time-tracker-control {
  color: var(--color-text-main);

  $this: &;
  &__time {
    font-size: 13px;
    line-height: 1;
    color: var(--color-text-main);
    margin-left: 3px;
  }

  &__icon {
    position: relative;
    top: 1px;
    font-size: 14px;
    cursor: pointer;
    transition-property: color;
    transition-duration: 0.3s;
    &:hover {
      color: var(--color-text-link);
    }
  }

  .anticon-pause-circle {
    position: relative;
    top: 1px;
    display: inline-block;
  }

  &_hidden {
    display: none;
  }
  &_tracking {
    #{$this}__time {
      color: var(--color-text-link);
    }
  }
  &_fixed {
    width: 5px;
  }
}
