.task-panel-wrap {
  position: relative;
  max-width: 1000px;
  width: 100%;
  overflow: hidden;
  height: 100%;
}

.task-panel {
  width: 100%;
  height: 100%;
  border-radius: 3px;
  background-color: var(--color-background);
  display: flex;
  flex-direction: column;
  &__created {
    flex-shrink: 0;
    margin-top: -15px;
    margin-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;
    line-height: 1.1;
    text-align: right;
  }

  &__created-title {
    font-weight: 500;
    color: var(--color-text-secondary);
    font-size: 12px;
    text-transform: uppercase;
  }

  &__created-value {
    text-align: right;
    font-size: 14px;
    white-space: nowrap;
    color: var(--color-text-secondary);
  }

  .custom-scrollbar-content {
    height: 100%;
  }

  &__bar {
    margin-bottom: 11px;
  }

  &__title-wrap {
    padding: 0 10px;
  }

  &__content {
    flex-grow: 1;
    height: 100%;
    background-color: var(--color-background);
  }

  &__title {
    margin-bottom: 9px;
    font-size: 18px;
    line-height: 1.33;
    font-weight: 600;
    color: var(--color-text-main);
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    .bp3-editable-text-content {
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    &::before {
      display: none;
    }
    &::after {
      content: '';
      position: absolute;
      top: -3px;
      bottom: -3px;
      left: -3px;
      right: -3px;
      border: 1px solid var(--color-text-link);
      border-radius: 2px;
      opacity: 0;
      transition: opacity 0.3s;
      pointer-events: none;
    }

    &.bp3-editable-text-editing {
      .bp3-editable-text-input {
        &::placeholder {
          opacity: 0;
        }
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }
      }
      &::after {
        opacity: 1;
      }
    }
  }

  &__section-title {
    margin-bottom: 0;
    margin-left: 4px;
    font-size: 14px;
    line-height: 1;
    color: #096dd9;
    font-weight: 400;
  }

  &__filter-button {
    width: 22px;
    height: 22px;
  }
  &__editor {
    padding: 0 8px;
  }
}

.task-panel-content {
  display: flex;
  flex-direction: column;
  &__section {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    &_activities {
      flex-grow: 1;
      .task-activities-section {
        height: 100%;
        &__content {
          height: 100%;
          padding-bottom: 40px;
        }
      }
    }

    .smartsuite-link {
      padding: 0 16px;
    }
  }
}
