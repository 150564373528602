.epic-details-description {
  &__editor {
    padding-left: 0;
    .ProseMirror {
      padding: 8px;
      transition-property: padding;
      transition-duration: 0.2s;
    }
  }
}

.epic-details-description.is-disabled {
  cursor: not-allowed;
}
