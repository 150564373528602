.sprint-details {
  &__dates {
    display: flex;
    align-items: flex-start;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 30px;
  }
  &__dates-item {
    width: calc((100% / 3) - 15px);
    margin-left: 15px;
    margin-right: 15px;
  }
}
