.tooltip-grouped-events {
  margin-top: 10px;
}

.fc-event,
.fc-event:hover {
  color: var(--color-text-main);
}

.fc {
  padding: 20px;
  color: var(--color-text-main);

  --fc-border-color: var(--color-border);
  --fc-today-bg-color: var(--color-background-selected);
  --fc-neutral-bg-color: var(--color-background-disabled);
}

.calendar-tracker {
  &.ant-layout {
    min-height: 100%;
  }

  .fc-header-toolbar {
    position: relative;
  }

  .fc-event,
  .fc-v-event {
    background-color: var(--color-fc-state-current-selection);
    border-color: var(--color-fc-state-current-selection);
    overflow: hidden;

    // For a time slot added via Add Time Manually modal
    &.fc-event-mirror > .fc-event-main,
    &.fc-event-draggable > .fc-event-main {
      color: white;
    }

    // regular time slots
    & > .fc-event-main {
      color: var(--color-text-main);
    }

    &.fc-state-grouped {
      border-width: 2px;
      border-style: dashed;
    }

    &.fc-state-unknown {
      background-color: var(--color-fc-state-unknown);
      border-color: var(--color-fc-state-unknown);
      &:hover {
        filter: brightness(0.9);
      }
      &.fc-state-grouped {
        border-color: var(--state-unknown-grouped-border-color);
        filter: brightness(0.85);
      }
    }

    &.fc-state-below-normal {
      background-color: var(--color-fc-state-below-normal);
      border-color: var(--color-fc-state-below-normal);
      &:hover {
        filter: brightness(0.9);
      }
      &.fc-state-grouped {
        filter: brightness(0.85);
      }
    }
    &.fc-state-normal {
      background-color: var(--color-fc-state-normal);
      border-color: var(--color-fc-state-normal);
      &:hover {
        filter: brightness(0.9);
      }
      &.fc-state-grouped {
        filter: brightness(0.85);
      }
    }
    &.fc-state-above-normal {
      background-color: var(--color-fc-state-above-normal);
      border-color: var(--color-fc-state-above-normal);
      &:hover {
        filter: brightness(0.9);
      }
      &.fc-state-grouped {
        filter: brightness(0.85);
      }
    }
  }

  .fc {
    .fc-event-main {
      .bp3-popover-wrapper {
        min-height: 1px;
      }

      .bp3-popover-wrapper,
      .bp3-popover-target {
        height: 100%;
      }

      .bp3-popover-target {
        > div {
          height: 100%;
        }
      }
    }
    .fc-event-main-frame {
      display: flex;
      flex-direction: column;
    }
    .fc-event-title {
      position: static;
      font-size: 12px;
    }
    .fc-event-time {
      font-size: 12px;
      &::after {
        display: none;
      }
    }
  }

  .fc-toolbar-chunk {
    display: flex;
    align-items: center;
    &:nth-child(2) {
      display: inline-flex;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &:first-child {
      padding-left: 70px;

      .fc-button {
        text-transform: capitalize;
        background-color: var(--color-background);
        font-size: 14px;
        color: #595959;
        border: 1px solid var(--color-border);
        transition-property: color, border-color, background-color;
        transition-duration: 0.3s;

        &.fc-button-primary:not(:disabled):active,
        &.fc-button-primary:not(:disabled).fc-button-active,
        &:hover {
          background-color: var(--color-text-link);
          color: #fff;
          border-color: var(--color-text-link);
          box-shadow: none;
        }
      }

      .fc-filterButton-button {
        background: none;

        .fc-icon {
          &::before {
            content: '';
          }
        }

        &:hover {
          background: none;
        }
      }
    }
    &:nth-child(2) {
      > div {
        display: flex;
        align-items: center;
      }

      .fc-prev-button {
        margin-right: 10px;
        box-shadow: none;
      }

      .fc-next-button {
        margin-left: 10px;
        box-shadow: none;
      }

      .fc-button {
        background: none;
        border: none;

        .fc-icon {
          &::before,
          &::after {
            color: var(--color-text-link);
          }
        }
      }

      .fc-button-primary:not(:disabled):active,
      .fc-button-primary:not(:disabled).fc-button-active {
        background: none;
        border: none;
        box-shadow: none;
      }
    }
  }

  .fc-today-button {
    &.fc-button {
      text-transform: capitalize;
      border-radius: 2px;
      background: none;
      color: var(--color-text-link);
      font-size: 14px;
      border: 1px solid var(--color-text-link);
      cursor: pointer;
      transition-property: color, background-color;
      transition-duration: 0.3s;
      &:focus {
        box-shadow: none;
      }

      &:hover {
        background-color: var(--color-text-link);
        color: #fff;
        border: 1px solid var(--color-text-link);
      }

      &:disabled {
        color: rgba(0, 0, 0, 0.25);
        background: #f5f5f5;
        border-color: var(--color-border);
        text-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        line-height: 1.2;
        cursor: not-allowed;
      }
    }
  }

  &__button-filter {
    position: absolute;
    top: 40px;
    left: 36px;
    z-index: 2;
  }

  .fc-view-container {
    flex: 1 1 0;
    background-color: var(--color-background);
  }

  .fc {
    background-color: var(--color-background);
    min-height: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
  }

  .fc-sat,
  .fc-sun {
    background-color: rgba(#c4c4c4, 0.15);
  }

  .ant-layout-content {
    background-color: #313f4d;
  }

  .fc-unthemed {
    .fc-today {
      background-color: #d6ebf0;
    }
  }
}

.fc-daygrid-day-events
  .fc-daygrid-event-harness.fc-daygrid-event-harness-abs
  + .fc-daygrid-event-harness {
  margin-top: 0 !important;
}

.fc-dayGridMonth-view {
  .fc-daygrid-day-frame.fc-scrollgrid-sync-inner {
    cursor: pointer;
  }
}

.fc-toolbar-title {
  color: var(--color-text-main);
}
