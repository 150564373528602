.sprint-details-date-picker {
  &__title {
    margin-bottom: 2px;
    font-size: 14px;
    color: var(--color-text-main);
    font-weight: 400;
  }

  &__button {
    color: var(--color-text-main);

    .bp3-input {
      box-shadow: none;
      border: 1px solid var(--color-border);
      cursor: pointer;
      text-align: left;
      padding-right: 20px;
      caret-color: transparent;
    }
    .bp3-input:focus,
    .bp3-input.bp3-active {
      box-shadow: none;
    }
  }

  &__calendar {
    position: relative;
  }

  &__icon {
    color: var(--color-text-main);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
    z-index: 2;
    pointer-events: none;
  }
}
