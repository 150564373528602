.task-name-editor {
  border: 1px solid var(--color-background);

  &.focused {
    border: 1px solid var(--color-text-link);
    border-radius: 2px;
  }
  .ProseMirror {
    border-bottom: 0;
    min-height: 23px;
    padding: 3px;
    .empty-node {
      font-size: 18px;
      color: rgba(92, 112, 128, 0.6);
      &::before {
        white-space: normal;
      }
    }
    p {
      font-size: 18px;
      font-weight: 600;
      color: var(--color-text-main);
    }
  }
  margin-bottom: 5px;
}
