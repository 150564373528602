.reorder-menu {
  .bp3-popover-content {
    min-width: 250px;
    overflow: hidden;
  }
  &.bp3-popover {
    &.bp3-minimal {
      margin-top: 0 !important;
    }
  }
}

.reorder-menu-content {
  $this: &;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 200px;
  .custom-scrollbar-content {
    padding: 10px;
  }
  &__button {
    display: inline-block;
    padding: 3px 15px;
    cursor: pointer;
    color: #ffffff;
    background-color: var(--color-text-link);
    border-radius: 2px;
    border: 0;
    margin-top: 5px;
    outline: none;
  }

  &__trigger {
    position: relative;
    display: block;
    width: 100%;
    height: 20px;
    z-index: 20;
    margin: -10px 0;
    &:hover {
      &::before,
      &::after {
        opacity: 1;
      }
    }
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background-color: #5a9ed3;
      opacity: 0;
      transition-property: opacity;
      transition-duration: 0.3s;
    }
    &::after {
      left: -4px;
      width: 4px;
      height: 4px;
      border-radius: 50%;
    }
    &::before {
      left: -4px;
      width: 80px;
      height: 1px;
    }
  }

  &__item {
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 0 2px;
    cursor: default;

    &.is-active {
      font-weight: 600;
      background-color: #eceaea;
    }
  }

  &__item-label {
    display: block;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.reorder-menu-item {
  outline: none;
  display: flex;
  align-items: center;
  &__text {
    margin-right: 2px;
  }
}
