.priority-select {
  $this: &;

  &__button {
    padding: 0;
    min-width: 0;
    max-height: 23px;
    min-height: 23px;
    background: none;
    color: inherit;
    border: none;
    font: inherit;
    cursor: pointer;
    outline: inherit;

    &_hotfix {
      color: #fa8c16;
      border-color: #fa8c16;
    }

    &_trivial {
      color: var(--color-text-inactive);
      border-color: var(--color-text-inactive);
    }

    &_blocker {
      color: #d4380d;
      border-color: #d4380d;
    }

    &_major {
      color: var(--color-text-link);
      border-color: var(--color-text-link);
    }

    &_minor {
      color: #389e0d;
      border-color: #389e0d;
    }

    &_disabled {
      color: #bbb;
      border-color: #ddd;
      cursor: not-allowed;
    }

    &_bordered {
      border: 1px solid;
      padding: 0 2px 0 2px;
      border-radius: 2px;
    }

    &_extended {
      padding: 0 4px 0 4px;

      .bp3-button-text {
        display: flex;
        align-items: center;
      }

      #{$this}__button-text {
        position: relative;
        top: 1px;
        margin-left: 3px;
        font-size: 12px;
        line-height: 1;
      }
    }
  }
}
