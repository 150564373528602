.custom-scrollbar {
  position: relative;
  width: 100%;
  height: 100%;
  &.is-scrolling {
    .custom-scrollbar-thumb {
      opacity: 1;
    }
  }
}

.custom-scrollbar-scroller {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}

.custom-scrollbar-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  inset: 0;
  overflow: hidden;
}

.custom-scrollbar-thumb {
  cursor: pointer;
  border-radius: 16px;
  transition-property: opacity;
  transition-duration: 0.3s;
  opacity: 0;
  &_y {
    width: 100%;
  }
  &_x {
    height: 100%;
  }
}
.custom-scrollbar-track {
  position: absolute;
  overflow: hidden;
  border-radius: 4px;
  user-select: none;
  background-color: transparent;

  &:hover {
    .custom-scrollbar-thumb {
      opacity: 1;
    }
  }
  &_y {
    width: 7px;
    height: calc(100% - 10px);
    top: 5px;
    right: 3px;
  }
  &_x {
    left: 10px;
    height: 7px;
    width: calc(100% - 10px);
    bottom: 5px;
  }
}
