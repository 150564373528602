.subtasks-header {
  display: flex;
  align-items: center;
  padding: 0 16px;
  &__right {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  &__initial-estimate {
    position: relative;
    top: 1px;
    display: inline-block;
    margin-left: 8px;
    padding: 2px 2px 3px;
    font-size: 10px;
    line-height: 1;
    color: var(--color-text-main);
    background-color: var(--color-background-hover);
    border-radius: 2px;
  }
}
