.project-details-info-deadline {
  .bp3-input {
    background-color: var(--color-background);
    box-shadow: none;
    padding: 0;
    text-align: left;
    cursor: pointer;
    caret-color: transparent;
    &::placeholder {
      color: var(--color-text-inactive);
    }
  }
}
