.linked-tasks {
  &__header {
    padding: 0 16px;
  }
  &__header-button {
    cursor: pointer;
    margin-bottom: 5px;
  }
}

.linked-tasks-popover {
  $this: &;
  max-width: 271px;
  min-width: 271px;
  background-color: #fafafa;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
  border-radius: 2px;
  .bp3-popover-arrow {
    &::before {
      border-radius: 0;
    }
  }

  .bp3-popover-arrow-border {
    border: none;
    fill: transparent;
  }

  .bp3-popover-arrow-fill {
    fill: #fafafa;
  }

  .bp3-popover-content {
    padding: 8px 8px 16px;
    background-color: #fafafa;
  }

  &__no-results {
    display: inline-block;
    font-style: italic;
    line-height: 1;
    padding-bottom: 8px;
    font-size: 12px;
    color: var(--color-text-secondary);
  }

  &__title {
    line-height: 1.2;
    margin-bottom: 6px;
    font-size: 14px;
    font-weight: 600;
    color: #595959;
  }

  &__search-input {
    &.ant-input-affix-wrapper {
      padding: 2px 8px;
    }
    &.ant-input-affix-wrapper-focused,
    &:focus {
      box-shadow: none;
    }

    .ant-input-suffix {
      align-self: center;
    }

    .ant-input {
      font-size: 12px;
    }
  }

  &__tasks-wrap {
    margin-top: 4px;
    .custom-scrollbar-track_y {
      width: 5px;
      right: -7px;
    }
  }

  &__tasks {
    margin-left: 0;
    margin-bottom: 0;
    padding-left: 0;
  }

  &__task {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style-type: none;
    &:focus {
      outline: none;
    }

    &_tag {
      padding: 9px 8px 8px;
      background-color: #ffffff;
      border: 1px solid #69c0ff;
      border-radius: 2px;

      &:not(:last-child) {
        margin-bottom: 4px;
      }

      #{$this}__task-name {
        max-width: 148px;
        line-height: 1.2;
      }
    }

    &_result {
      cursor: pointer;
      &:not(:last-child) {
        margin-bottom: 8px;
      }

      #{$this}__task-name {
        max-width: 164px;
        transition: color 0.3s;
      }

      #{$this}__task-code {
        top: 1px;
        transition: color 0.3s;
        &::after {
          transition: background-color 0.3s;
        }
      }

      &:hover,
      &:focus-within {
        #{$this}__task-code {
          color: #40a9ff;
          &::after {
            background-color: #91d5ff;
          }
        }
        #{$this}__task-name {
          color: #40a9ff;
        }
      }
      &:hover {
        #{$this}__result-button {
          color: var(--color-text-link);
        }
      }
    }
  }

  &__task-info {
    display: flex;
    align-items: center;
  }

  &__task-code {
    position: relative;
    flex-shrink: 0;
    padding-right: 8px;
    font-weight: 600;
    font-size: 12px;
    line-height: 1;
    letter-spacing: -0.8px;
    color: var(--color-text-muted);
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 1px;
      height: 12px;
      background-color: #e8e8e8;
      transition: background-color 0.3s;
    }
  }

  &__task-name {
    width: 100%;
    padding-left: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
    letter-spacing: -0.3px;
    line-height: 1;
    color: var(--color-text-main);
  }

  &__results {
    margin-left: 0;
    margin-bottom: 0;
    padding-bottom: 12px;
    padding-left: 0;
  }

  &__results-wrap {
    margin-top: 8px;
    margin-bottom: 12px;
    border-bottom: 1px solid var(--color-text-inactive);
    .custom-scrollbar-track_y {
      width: 5px;
      right: -7px;
    }
  }

  &__result-button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0;
    font-size: 14px;
    line-height: 1;
    color: var(--color-text-inactive);
    background: none;
    border: 0;
    transition: color 0.3s;
    &:hover,
    &:focus {
      outline: none;
      color: var(--color-text-link);
    }
    .bp3-icon {
      display: block;
    }
  }
}
