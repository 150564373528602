.add-time-manually-input {
  input,
  textarea {
    color: var(--color-text-main);
    outline: none;
    border-radius: 3px;
    transition-property: border-color;
    transition-duration: 0.3s;
    border: 1px solid var(--color-border-input);
    box-shadow: none;
    &::placeholder {
      transition-property: opacity;
      transition-duration: 0.3s;
      color: var(--color-text-placeholder);
      box-shadow: none;
    }
    &:focus {
      border-color: #40a9ff;
      box-shadow: none;
      &::placeholder {
        opacity: 0;
      }
    }
  }
}
