.project-details-info {
  &__row {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  &__item {
    max-width: 140px;
    width: 100%;
    margin-bottom: 20px;
    overflow: hidden;
    &:not(:last-child) {
      margin-right: 25px;
    }
  }
}
