.status-select {
  $this: &;

  &__divider {
    width: 100%;
    height: 1px;
    background-color: var(--color-background-divider);
  }

  .bp3-button {
    &#{$this}__button {
      border: 1px solid #adc6ff;
      color: #2f54eb;
      font-size: 12px;
      padding-left: 8px;
      padding-right: 8px;
      min-height: 0;
      max-height: 26px;

      &_draft {
        color: var(--color-status-draft);
        border-color: var(--color-border-status-draft);
      }

      &_todo {
        color: var(--color-status-todo);
        border-color: var(--color-border-status-todo);
      }

      &_in-progress,
      &_fix-in-progress {
        color: var(--color-status-in-progress);
        border-color: var(--color-border-status-in-progress);
      }

      &_bug-found {
        color: var(--color-status-bug-bound);
        border-color: var(--color-border-status-bug-bound);
      }

      &_qa {
        color: var(--color-status-qa);
        border-color: var(--color-border-status-qa);
      }

      &_accepted {
        color: var(--color-status-accepted);
        border-color: var(--color-border-status-accepted);
      }

      &_cancelled {
        color: var(--color-status-cancelled);
        border-color: var(--color-border-status-cancelled);
      }

      &_verified {
        color: var(--color-status-verified);
        border-color: var(--color-border-status-verified);
      }

      &_on-review {
        color: var(--color-status-on-review);
        border-color: var(--color-border-status-on-review);
      }
      &_ready-for-testing {
        color: var(--color-status-ready-for-testing);
        border-color: var(--color-border-status-ready-for-testing);
      }
      &_on-hold {
        color: var(--color-status-on-hold);
        border-color: var(--color-border-status-on-hold);
      }

      &_disabled {
        cursor: not-allowed;
      }
    }
  }
}
