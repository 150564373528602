.page-search-target {
  color: var(--color-text-main);

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 5px;
  top: 2px;
  &__icon {
    position: relative;
    width: 14px;
    transition-property: fill;
    transition-duration: 0.3s;
    &:hover {
      fill: var(--color-text-link);
    }
  }
}

.bp3-menu {
  > div {
    &:first-child {
      .page-search-item {
        &__section-title {
          border-top: 0;
          margin-top: 0;
          padding-top: 0;
        }
      }
    }
  }
  .page-search-list-item {
    &__label {
      flex-shrink: 1;
    }
  }
}

.page-search-item {
  padding-left: 0;
  &:not(:last-child) {
    position: relative;
    margin-bottom: 10px;
    padding-bottom: 10px;
    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 10px;
      width: calc(100% - 20px);
      height: 1px;
      background-color: #ececec;
    }
  }
  &__section-title {
    font-size: 14px;
    color: #40a9ff;
    font-weight: 600;
    padding-left: 7px;
    padding-right: 10px;
    border-top: 1px solid var(--color-text-secondary);
    padding-top: 5px;
    text-transform: uppercase;
  }
  .bp3-menu-item {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.page-search-omnibar {
  background-color: var(--color-background-popover);
  &__filter {
    margin-top: 10px;
    padding-left: 10px;
  }
  &.bp3-omnibal {
    width: 600px;
  }
  &:focus {
    outline: none;
  }
  .bp3-menu {
    padding: 0;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  &__empty {
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    color: #7e7d7d;
  }
}
