.sprint-details-statistics-table {
  color: var(--color-text-main);

  &__col-inner {
    cursor: pointer;
  }
  &__col {
    border: 1px solid var(--color-border-input);
    padding: 10px 16px;
    &.is-red {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 1px solid #f00;
      }
      color: #f00;
    }
    &.is-yellow {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: -1px;
        left: -1px;
        right: -1px;
        border: 1px solid #fa8c16;
        bottom: -1px;
      }
      color: #fa8c16;
    }
    &_head {
      font-weight: 600;
      vertical-align: bottom;
    }
    &_foot {
      font-weight: 600;
    }
  }

  &__user-name {
    display: inline-block;
    margin-left: 5px;
  }
}
