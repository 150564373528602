.change-user-password-button {
  display: inline-block;
  background-color: var(--color-text-link);
  border-radius: 2px;
  border: none;
  color: #ffffff;
  font-size: 14px;
  padding: 4px 8px;
  cursor: pointer;
  outline: none;
}
