.breadcrumb-select {
  display: block;
  max-width: 60px;
  width: 100%;
  .bp3-popover-target {
    max-width: 60px;
    width: 100%;
  }
  .ant-btn {
    max-width: 60px;
    width: 100%;
    max-height: 17px;
    min-height: 0;
    padding-left: 6px;
    padding-right: 7px;
    padding-top: 3px;
    padding-bottom: 2px;
    font-size: 10px;
    color: var(--color-text-secondary);
    border: 1px solid var(--color-border);
    border-radius: 2px;
    white-space: nowrap;
    box-shadow: none;
    background-color: transparent;

    > span {
      position: relative;
      bottom: 1px;
      line-height: 1.1;
      display: block;
      overflow: hidden;
    }
  }

  &_extended {
    .ant-btn {
      max-width: 150px;
    }
  }

  .bp3-menu-item {
    font-size: 12px;
  }

  .bp3-popover,
  .bp3-transition-container,
  .bp3-menu {
    max-width: 100%;
  }

  &__button {
    &_disabled {
      cursor: not-allowed;
    }
  }
}

.breadcrumbs-popover {
  background-color: #ffffff;
  .ant-empty {
    padding-bottom: 5px;
  }
}

.breadcrumbs-portal {
  .bp3-transition-container {
    width: 230px;
    .breadcrumbs-popover {
      width: 230px;
      .bp3-menu {
        width: 230px;
        max-width: 100%;
      }
    }
  }
}
