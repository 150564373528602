.task-panel-bar {
  $this: &;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-background-darker);
  padding: 8px 16px;
  border-radius: 3px 3px 0 0;

  &__left,
  &__right {
    display: flex;
    align-items: center;
  }

  &__priority {
    margin-right: 4px;
  }

  &__shirt {
    margin-right: 8px;
  }

  &__assignee-select {
    margin-right: 5px;
  }

  &__shirt-estimation {
    font-size: 13px;
    color: var(--color-text-main);
  }

  &__estimation-current {
    &_active {
      color: var(--color-text-link);
    }
  }

  &__estimation-icon {
    cursor: pointer;
  }

  &__extended-estimate {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
  }

  &__slash {
    color: var(--color-text-secondary);
  }
}
