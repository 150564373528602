.ProseMirror .empty-node::before {
  float: left;
  color: #aaa;
  pointer-events: none;
  height: 0;
  font-style: italic;
}

.ProseMirror p.empty-node:first-child::before {
  content: attr(data-placeholder);
}
