.dashboard-modal-users-suggest {
  &.users-suggest-portal {
    .bp3-transition-container {
      z-index: 20;
    }
  }
}

.dashboard-page {
  background: #313e4d;

  .users-suggest-popover {
    z-index: 21;
  }

  &__title-filter {
    font-size: 15px;
    margin-right: 5px;
    cursor: pointer;
  }

  &_empty {
    display: flex;
    justify-content: center;
  }

  &__title {
    &.ant-typography {
      display: flex;
      align-items: center;
      font-size: 24px;
      line-height: 1.33;
      color: #ffffff;
      font-weight: 400;
      margin-bottom: 0;
    }
  }

  &__add-project-btn {
    &.ant-btn {
      height: auto;
      background-color: transparent;
      color: #8492a3;
      border-color: #8492a3;

      &:hover,
      &:active,
      &:focus {
        background-color: transparent;
        border-color: #a1b2c7;
        color: #a1b2c7;
      }
    }

    &_micro {
      &.ant-btn {
        padding: 4px 11px;
      }
    }

    &_small {
      &.ant-btn {
        padding: 8px 11px;
      }
    }

    &_big {
      &.ant-btn {
        padding: 25px 62px;
      }
    }
  }

  &__project-cards {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0 -4px;
  }

  &__project-card {
    margin: 0 4px;
  }

  &__empty-title {
    &.ant-typography {
      margin-bottom: 28px;
    }
  }
}

.dashboard-modal-add-new-project {
  $this: &;
  &__cancel-button {
    background-color: var(--color-background);
    color: var(--color-text-main);

    &:hover {
      color: var(--color-text-link);
      background-color: var(--color-background);
    }
  }
}
