.dashboard-project-card {
  display: flex;
  align-items: center;
  max-width: 242px;
  width: 100%;
  padding: 8px;
  padding-right: 16px;
  margin-bottom: 8px;
  border-radius: 2px;
  background-color: var(--color-background);

  &__img {
    width: 56px;
    height: 56px;
    flex-shrink: 0;
    margin-right: 8px;
  }

  &__content {
    overflow: hidden;
  }

  &__name {
    white-space: nowrap;
    overflow: hidden;
    color: var(--color-text-main);
    font-size: 16px;
    line-height: 1.2;
    margin-bottom: 2px;
  }

  &__abbr {
    color: var(--color-text-secondary);
  }

  &__participants {
    color: var(--color-text-secondary);
    font-size: 13px;
  }
}
