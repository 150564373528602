.add-task-group-form {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: var(--color-background);
  border-top: 1px solid var(--color-border);

  &__input-wrap {
    position: relative;
    flex-grow: 1;
    margin-right: 10px;
  }

  &__input {
    display: block;
    width: 100%;
    border-radius: 3px;
    font-size: 14px;
    padding: 5px;
    background-color: transparent;
    border: 1px solid var(--color-border-input);
    color: var(--color-text-main);
    outline: none;
    &:focus {
      border-color: var(--color-text-link);
    }
    &::placeholder {
      color: var(--color-text-placeholder);
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }

  &__button {
    &:not(:last-child) {
      margin-right: 5px;
    }
  }
}
