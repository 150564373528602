.add-time-manually-activities-input {
  .bp3-input {
    padding: 4px 10px 5px;
  }
}

.add-time-manually-activities-button {
  display: block;
  width: 100%;
  text-align: left;
  outline: none;
  border-radius: 3px;
  transition-property: border-color;
  transition-duration: 0.3s;
  border: 1px solid var(--color-border-input);
  cursor: pointer;
  background-color: var(--color-background);
  color: var(--color-text-inactive);
  height: 30px;
  &.is-active {
    color: var(--color-text-main);
  }
}
