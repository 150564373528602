.task-type {
  display: inline-block;
  padding: 1px 3px 1px;
  border-radius: 2px;
  background-color: var(--color-text-link);
  color: #fff;
  font-size: 10px;
  height: 17px;
  vertical-align: middle;
  margin-left: 2px;
  margin-right: 2px;
  user-select: none;
  &:hover {
    color: #fff;
  }
}
