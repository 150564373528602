.users-list {
  $this: &;
  display: flex;
  flex-direction: column;
  max-width: 300px;
  width: 100%;
  height: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
  background-color: var(--color-background);
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.15);
    opacity: 0;
    transition: opacity 0.3s;
    pointer-events: none;
  }

  &.is-active {
    &::before {
      opacity: 1;
    }
  }

  &__header {
    max-width: 300px;
    width: 100%;
    display: flex;
    flex-shrink: 0;
    max-height: 40px;
  }

  &__filter {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 40px;
    padding: 10px 0;
    border-bottom: 1px solid var(--color-border);
    border-top: 1px solid var(--color-border);
    cursor: pointer;
    &.is-active {
      border-top-color: var(--color-text-link);
      border-bottom-color: var(--color-text-link);
      border-left: 1px solid var(--color-text-link);
    }
  }

  &__search {
    display: flex;
    align-items: center;
    max-width: 260px;
    width: 100%;
    background-color: var(--color-background);

    .ant-input-affix-wrapper {
      background-color: var(--color-background);
      border-color: var(--color-border);
    }

    &.is-panel-active {
      .ant-input-affix-wrapper {
        border-left-color: var(--color-text-link);
      }
    }

    .ant-input {
      background-color: var(--color-background);
      color: var(--color-text-main);

      &.ant-input-focused,
      &:focus {
        outline: none;
      }
    }

    .ant-input-group {
      background-color: var(--color-background);
      display: flex;
      height: 36px;
    }

    .ant-input-group-addon {
      background-color: var(--color-background);
      width: auto;
    }

    .ant-input-search-button {
      background-color: var(--color-background);
      color: var(--color-text-main) !important;
      border-top-color: var(--color-border);
      border-bottom-color: var(--color-border);
      height: 100%;
    }

    .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
      border-radius: 0;
      left: 0;
    }

    .ant-input-search
      .ant-input-group
      .ant-input-affix-wrapper:not(:last-child) {
      border-radius: 0;
    }

    .ant-input-search
      > .ant-input-group
      > .ant-input-group-addon:last-child
      .ant-input-search-button {
      border-radius: 0;
      border-right: 0;
      border-left: 0;
      &:hover {
        border-color: var(--color-border);
      }
    }

    .ant-input-affix-wrapper {
      border-radius: 0;
      &:focus,
      &.ant-input-affix-wrapper-focused {
        box-shadow: none;
        border-color: var(--color-text-link);
      }
    }
  }

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    max-width: 300px;
    width: 100%;
    padding: 7px 8px;
    border-bottom: 1px solid var(--color-border);
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      top: -1px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: transparent;
    }

    &_selected {
      background-color: var(--color-background-selected);
      border-bottom: 1px solid #69c0ff;

      &::before {
        background-color: #69c0ff;
      }
    }

    &_inactive {
      background-color: var(--color-background-hover);
      color: var(--color-text-secondary);

      #{$this}__item-photo {
        filter: grayscale(100%);
      }

      #{$this}__item-name {
        color: var(--color-text-secondary);
      }

      #{$this}__item-role {
        color: var(--color-text-secondary);
      }

      &_selected {
        background-color: var(--color-background-selected);
      }
    }

    &_invited {
      #{$this}__item-status {
        color: #fa8c16;
        position: absolute;
        right: 3px;
        top: 1px;
        font-size: 10px;
      }
    }
  }

  &__item-photo {
    &.ant-avatar {
      position: relative;
      flex-shrink: 0;
      margin-right: 8px;
      border-radius: 5px;
    }
  }

  &__item-name {
    margin-right: 3px;
    margin-bottom: 1px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 1.2;
    color: var(--color-text-main);
    white-space: nowrap;
    overflow: hidden;
  }

  &__item-info {
    display: flex;
    align-items: baseline;
    margin-bottom: 2px;
  }

  &__items {
    overflow: auto;
  }

  &__item-role {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: var(--color-text-link);
  }

  &__item-email {
    font-weight: normal;
    font-size: 13px;
    color: var(--color-text-secondary);
    line-height: 1.2;
  }

  &__item-about {
    width: 100%;
    overflow: hidden;
    margin-right: 5px;
  }

  &__item-status {
    margin-left: auto;
    font-size: 12px;
    flex-shrink: 0;
  }

  &__item-initials {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    color: #ffffff;
  }

  &__add {
    max-width: 300px;
    width: 100%;
    margin-top: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    padding: 10px;
    background-color: var(--color-background-selected);
    border: 0;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s;
    &:hover {
      background-color: #d2f1ff;
      #{$this}__add-label,
      #{$this}__add-icon {
        color: #42acf8;
      }
    }
  }

  &__add-label {
    margin-right: 5px;
    font-weight: 600;
    font-size: 14px;
    line-height: 1;
    color: #69c0ff;
    transition: color 0.3s;
  }

  &__add-icon {
    transition: color 0.3s;
    color: #69c0ff;
  }
}
