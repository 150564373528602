.members-select-target {
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed var(--color-text-secondary);
  cursor: pointer;
  border-radius: 4px;
}

.members-popover {
  .bp3-input-action {
    display: none;
  }
}
