.subtask-type-select {
  $this: &;

  &__button {
    padding: 0;
    min-width: 0;
    min-height: 0;
  }
}

.subtask-type-popover {
  &__item {
    display: flex;
    align-items: center;
  }

  &__item-icon {
    margin-right: 5px;
  }

  &__item-label {
    font-size: 14px;
    color: var(--color-text-main);
  }
}
