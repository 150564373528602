.subtasks-change-estimation {
  &.ant-popover {
    z-index: 9;
  }
  .ant-popover-inner {
    min-width: 400px;
    max-width: 400px;
  }

  .ant-popover-inner-content {
    padding: 0;
  }

  .ant-popover-arrow {
    display: none;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 9px 14px;
    background-color: #f7f7f7;
  }

  &__header-close {
    cursor: pointer;
  }

  &__header-title {
    margin-bottom: 0;
    line-height: 1;
  }

  &__body {
    padding: 9px 14px;
  }

  &__content {
    border: 1px solid var(--color-border);
    border-radius: 2px;
  }

  &__title {
    display: block;
    margin-bottom: 5px;
  }

  &__section {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__field {
    &.prose-mirror-editor {
      border: 1px solid var(--color-border);
      border-radius: 2px;
      .empty-node {
        font-size: 12px;
        &::before {
          white-space: normal;
        }
      }
      .ProseMirror {
        padding: 10px;
        min-height: 70px;
        max-height: 100px;
        overflow: auto;
      }
    }
    &::placeholder {
      transition-property: opacity;
      transition-duration: 0.2s;
      color: var(--color-text-inactive);
    }
    &:focus {
      &::placeholder {
        opacity: 0;
      }
    }
  }
}
