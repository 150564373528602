.task-panel-breadcrumbs {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
  padding-left: 16px;
  padding-right: 16px;

  &__bricks {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  &__wrap {
    margin-right: 20px;
    position: relative;
  }

  div {
    .task-panel-breadcrumbs {
      &__created {
        padding-left: 0;
        padding-right: 0;
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: auto;
    // flex-shrink: 0;
  }

  &__is-blocked {
    display: flex;
    &:not(:last-child) {
      margin-right: 5px;
    }
  }

  .ant-breadcrumb-separator {
    color: #d9d9d9;
  }

  &__wrap {
    > span {
      line-height: 1;
      display: flex;
      align-items: center;
    }
  }

  &__wrap {
    display: flex;
    align-items: center;
  }

  &__task-code {
    white-space: nowrap;
    top: 1px;
    color: var(--color-text-main);
    font-size: 12px;
    letter-spacing: -0.6px;
  }
}
