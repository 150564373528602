.report-page {
  background-color: #313f4d;
  &__main {
    .custom-scrollbar {
      flex-grow: 1;
      height: auto;
    }

    .custom-scrollbar-content {
      padding: 16px;
    }
  }

  .table-row-bold {
    td {
      font-weight: bold;
    }
  }

  .table-row-title {
    td {
      background: #f9f6f0;
    }

    td:nth-child(2) {
      font-weight: bold;
    }
  }

  .ant-table.ant-table-bordered .ant-table-title {
    display: none;
  }

  .ant-table.ant-table-bordered .ant-table-cell-fix-right-first:after,
  .ant-table.ant-table-bordered tbody > tr > td,
  .ant-table.ant-table-bordered tfoot > tr > td,
  .ant-table.ant-table-bordered tfoot > tr > th,
  .ant-table.ant-table-bordered thead > tr > th {
    border-right: 0;
  }

  &__content {
    position: relative;
    padding-top: 27px;
    min-height: 100%;
    border-radius: 3px;

    .ant-table-tbody > tr > td {
      background-color: var(--color-background) !important;
      color: var(--color-text-main) !important;
    }

    .ant-table-thead > tr > th {
      background-color: var(--color-background-selected) !important;
      color: var(--color-text-main) !important;
    }

    .ant-empty-description {
      color: var(--color-text-secondary);
    }
  }

  &__header {
    padding: 0 16px;
    margin-bottom: 19px;
  }

  &__apply-filter-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    padding-bottom: 1px;
    font-size: 16px;
    color: var(--color-text-link);
    border: none;
    border-bottom: 1px dashed var(--color-text-link);
    background: none;
    cursor: pointer;
    outline: none;
  }
}
