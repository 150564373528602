.filter-button {
  $this: &;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background-color: var(--color-background);
  border: 1px solid var(--color-text-link);
  border-radius: 2px;
  color: #fff;
  cursor: pointer;
  outline: none;
  transition-property: border-color, background-color;
  transition-duration: 0.3s;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &.is-active,
  &:hover {
    background-color: var(--color-text-link);
    #{$this}__filter-icon {
      color: #fff;
    }
  }

  &__filter-icon {
    color: var(--color-text-link);
    transition: color 0.3s;
    &_hidden {
      display: none;
    }
  }
}
