.tracker-status-container {
  color: var(--color-text-main);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  &__task {
    display: flex;
    align-items: center;
    min-width: 0;
  }
  &__task-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 5px;
  }
  &__task-activity {
    flex-shrink: 0;
    text-transform: lowercase;
  }
  &__col {
    flex-shrink: 0;
    &:not(:last-child) {
      margin-right: 8px;
    }
    &_task-name {
      flex-shrink: 1;
      max-width: 450px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @media screen and (max-width: 1400px) {
        max-width: 350px;
      }
    }
  }
}
