.edit-followers-popover {
  background-color: var(--color-background-popover);
  width: 100%;
  &.bp3-popover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }

  .bp3-popover-content {
    background-color: var(--color-background-popover);
    height: 345px;
    padding: 8px 12px;
  }

  &__target {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    background-color: var(--color-background-popover);
    border-radius: 2px;
    color: var(--color-text-link);
    cursor: pointer;
    font-size: 14px;
    border: 1px solid var(--color-text-link);
    margin-top: 2px;
    padding: 0;
    .bp3-icon {
      font-size: 12px;
    }
    &:hover,
    &.is-active {
      background-color: var(--color-text-link);
      color: #fff;
    }
  }

  &__users {
    overflow: hidden;
    height: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
  }

  &__title {
    margin-bottom: 8px;
    color: #595959;
    font-size: 14px;
    font-weight: 600;
  }
}

.edit-followers-portal {
  .bp3-transition-container {
    max-width: 220px;
    width: 100%;
  }
}
