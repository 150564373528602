.sprint-details-change {
  padding: 20px;
  .ant-input-number-handler-wrap {
    display: none;
  }

  &__buttons {
    margin-top: 20px;
  }

  &__button {
    &--submit {
      &.ant-btn {
        color: var(--color-text-link);
        border-color: var(--color-text-link);
        &:hover {
          color: #fff;
          background-color: var(--color-text-link);
        }
      }
    }
    &:not(:last-child) {
      margin-right: 5px;
    }
  }
}
