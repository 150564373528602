.sidebar-container {
  height: 100vh;
  left: 0;
  flex: 0 0 80px;
  max-width: 80px;
  min-width: 80px;
  width: 80px;
  z-index: 40;

  .ant-menu-item .anticon {
    margin-right: 0;
    font-size: 16px;
  }

  &__item-label {
    margin-left: 10px;
  }

  .ant-menu-inline-collapsed > .ant-menu-item,
  .ant-menu-inline-collapsed > .ant-menu-active {
    padding-left: 24px;
    padding-right: 26px;
    text-align: center;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    > a {
      overflow: hidden;
      display: block;
    }
  }
  .ant-menu-vertical .ant-menu-item {
    padding-left: 24px;
    padding-right: 24px;
    overflow: hidden;

    > a {
      overflow: hidden;
      display: block;
    }
  }
  .sidebar-logo {
    display: flex;
    align-items: center;
    // justify-content: center;
    height: 64px;
    &.is-expanded {
      justify-content: flex-start;
    }
  }
}
