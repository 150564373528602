.report-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__button-filter {
    margin-right: 8px;
  }

  &__button-copy {
    background-color: var(--color-background);
    color: var(--color-text-link);
    border-color: var(--color-text-link);
    &:hover {
      color: #fff;
      background-color: var(--color-text-link);
    }

    &.ant-btn[disabled] {
      background-color: transparent;
      color: var(--color-text-inactive);
      border-color: var(--color-text-inactive);
    }
  }

  &__left {
    display: flex;
    align-items: center;
  }

  &__entity-name {
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    margin-right: 8px;
  }

  &__project-abbr {
    color: var(--color-text-secondary);
  }

  &__entity-fullname {
    color: var(--color-text-link);
  }

  &__date-range {
    position: relative;
    padding-left: 8px;
    color: var(--color-text-secondary);
    font-weight: 500;
    letter-spacing: -0.5px;
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 1px;
      height: 13px;
      background-color: #e8e8e8;
    }
  }

  &__date {
    color: var(--color-text-main);
  }
}
