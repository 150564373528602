.error-page-link {
  display: inline-block;
  padding: 5px 16px;
  font-size: 14px;
  border-radius: 2px;
  color: #ffffff;
  background-color: var(--color-text-link);
  text-decoration: none;
  &:hover {
    color: #ffffff;
  }
}
