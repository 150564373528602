.task-group-details-title {
  font-weight: 400;
  color: var(--color-text-main);
  font-size: 21px;
  margin-bottom: 16px;
  &__textarea {
    &.bp3-editable-text.bp3-editable-text-editing::before {
      background-color: var(--color-background);
      color: var(--color-text-main);
      box-shadow: none;
      border: 1px solid var(--color-text-link);
    }
  }
}
