.project-details-info-item {
  &__title {
    color: var(--color-text-secondary);
    font-size: 11px;
    font-weight: 500;
    text-transform: uppercase;
  }
  &__editable-text {
    max-width: 120px;
    width: 100%;
  }
}
