.task-filter-popover {
  &.popover {
    .bp3-popover-content {
      .bp3-menu {
        background-color: var(--color-background);
        color: var(--color-text-main);
        border: 1px solid var(--color-text-secondary);
        max-height: 300px;
        // overflow: auto;
      }
    }
  }

  &__checkbox {
    &.ant-checkbox-wrapper {
      display: block;
      padding-top: 7px;
      padding-bottom: 7px;
      .ant-checkbox-inner {
        background-color: var(--color-background);
        border-color: var(--color-text-secondary);
      }

      .ant-checkbox-checked {
        .ant-checkbox-inner {
          border-color: var(--color-text-link);
          background-color: var(--color-background);
          &::after {
            left: 22%;
            width: 5px;
            height: 8px;
            transform: rotate(45deg) scale(1) translate(-50%, -60%);
            border-color: var(--color-text-link);
          }
        }
      }
    }
  }
}

.subtasks-filter-popover {
  width: 180px;
}
