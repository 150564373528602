.is-blocked-brick {
  display: inline-block;
  padding: 2px 5px 3px;
  font-size: 10px;
  line-height: 1.2;
  font-weight: 600;
  text-transform: uppercase;
  color: #ffffff;
  background-color: #f5222d;
  border-radius: 2px;
  flex-shrink: 0;
}
