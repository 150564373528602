.project-details-members-suggest {
  $this: &;
  &.users-suggest {
    max-width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    height: auto;
    align-items: stretch;
    .project-tile {
      flex-shrink: 0;
      height: 100%;
      width: 173px;
      height: 34px;
      margin-right: 4px;
    }
    #{$this}__button {
      .bp3-input {
        display: flex;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 1px solid var(--color-text-secondary);
        padding: 0;
        margin-bottom: 0;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }

  .users-suggest {
    &__input-wrapper {
      position: relative;
      order: 1;
      align-self: center;
      flex-shrink: 0;
      width: auto;
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--color-text-secondary);
    z-index: 3;
    pointer-events: none;
  }

  &__button-wrap {
    &.bp3-fill {
      width: auto;
    }
  }
}

.project-details-members {
  &__row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: -4px;
  }
  &__col {
    max-width: 173px;
    width: 100%;
    height: 34px;
    display: flex;
    align-items: center;
    padding: 2px 6px 2px 2px;
    .bp3-tag-remove {
      outline: none;
    }
    &:not(:last-child) {
      margin-bottom: 4px;
      margin-right: 4px;
    }
  }
  &__placeholder {
    flex-shrink: 0;
    color: var(--color-text-secondary);
    margin-right: 10px;
  }
  .bp3-popover-wrapper {
    margin-bottom: 4px;
  }
}

.project-details-members-tag {
  $this: &;

  &.project-tile {
    border-color: var(--color-border-project-tile);
  }

  &_inactive {
    background-color: var(--color-background-inactive-member);

    &.project-tile {
      border-color: var(--color-text-secondary);
    }

    #{$this}__content {
      color: var(--color-text-secondary);
    }

    #{$this}__avatar {
      background-color: var(--color-text-secondary) !important;
      &_has-img {
        filter: grayscale(100%);
      }
    }
  }

  &__content {
    display: flex;
    align-items: center;
    font-size: 13px;
  }

  &__avatar {
    &.ant-avatar {
      flex-shrink: 0;
      margin-right: 6px;
    }
  }
}
