.task-activities-section {
  &__header {
    border-bottom: 1px solid var(--color-border);
    padding: 4px 16px;
  }
  &__content {
    padding: 16px;
    background-color: var(--color-background);
    min-height: 145px;
  }
}
