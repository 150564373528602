.popover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  background-color: var(--color-background-popover);

  .bp3-menu {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .ant-empty {
    padding: 8px;
  }

  .me-label {
    margin-left: 6px;
    color: #aaa;
    font-style: italic;
    font-weight: lighter;
    font-size: small;
  }

  &.bp3-popover {
    &.bp3-minimal {
      margin-top: 5px !important;
    }

    &.no-margin {
      margin-top: 0 !important;
    }
  }

  .bp3-input-group {
    display: none;
  }

  &_with-search {
    .bp3-input-group {
      display: block;
      border: 1px solid var(--color-text-link);
      border-radius: 2px;
      margin: 4px;
      &:focus {
        box-shadow: none;
        outline: none;
      }
      input {
        &:focus {
          box-shadow: none;
          outline: none;
        }
      }
    }
    .bp3-input {
      box-shadow: none;
    }
  }

  .bp3-popover-content {
    padding: 0;

    .bp3-menu {
      padding: 0;
      max-width: 210px;
      min-width: 42px;
      max-height: 400px;

      .bp3-menu-item {
        padding-left: 12px;
        padding-right: 12px;
        border-radius: 0;
        color: var(--color-text-main);
        font-size: 14px;
        transition: background-color 0.3s;

        &_hotfix {
          .bp3-icon {
            color: #fa8c16;
          }
        }

        &_trivial {
          .bp3-icon {
            color: var(--color-text-inactive);
          }
        }

        &_blocker {
          .bp3-icon {
            color: #d4380d;
          }
        }

        &_major {
          .bp3-icon {
            color: var(--color-text-link);
          }
        }

        &_minor {
          .bp3-icon {
            color: #389e0d;
          }
        }

        &_selected {
          background-color: var(--color-background-selected);
        }

        &:hover {
          background-color: (--color-background-hover);
          color: var(--color-text-main);
        }

        &.bp3-active {
          background-color: var(--color-background-popover-selected);
        }

        &.bp3-multi {
          background-color: #137cbd;
          color: #ffffff;
          .me-label {
            color: #ffffff;
          }
        }
      }
    }
  }

  &_center-text {
    .bp3-menu {
      text-align: center;
    }
  }

  &_full-width {
    &.bp3-popover {
      width: 100%;
      max-width: 100% !important;
    }
    .bp3-popover-content {
      .bp3-menu {
        max-width: 100%;
        width: 100%;
      }
    }
  }
}
