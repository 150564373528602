$normalIndent: 15px;

.text-center {
    text-align: center;
}

.registration-form {
    margin: 150px auto;
    max-width: 400px;
    width: 100%;

    background: white;
    padding: $normalIndent;
    padding-bottom: 0;
    border: 1px solid #f0f0f0;

    .ant-form-item {
        margin-bottom: $normalIndent;
    }

}