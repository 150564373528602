.user-section-title {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  color: var(--color-text-inactive);
  margin: 0;
}

.user-title-divider {
  &.ant-divider {
    &.ant-divider-with-text-left {
      &::before {
        display: none;
      }

      .ant-divider-inner-text {
        padding-left: 0;
      }
    }

    &.ant-divider-with-text-right {
      &::after {
        display: none;
      }

      .ant-divider-inner-text {
        padding-right: 0;
      }
    }
  }
}

.user-field-title {
  font-size: 10px;
  color: var(--color-text-inactive);
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 0;
  white-space: nowrap;
}
