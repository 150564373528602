.size-select {
  $this: &;

  .bp3-button {
    min-width: 0;
    min-height: 0;
    width: 24px;
    height: 24px;
    padding: 0;
    text-transform: uppercase;
    border: 1px solid var(--color-text-inactive);
    border-radius: 2px;
    font-size: 13px;
    text-align: center;
    color: var(--color-text-secondary);
  }

  .bp3-button-text {
    line-height: 1;
  }

  .bp3-menu-item {
    text-align: center;
  }

  &_in-task {
    .bp3-button {
      font-size: 10px;
      height: 16px;
      border: 1px solid var(--color-border);
      border-radius: 4px;
    }
  }

  &_exceeded-estimate {
    .bp3-button {
      border-color: #f5222d;
      color: #f5222d;
    }
  }

  &_no-estimate {
    .bp3-button {
      border-color: #fa8c16;
      color: #fa8c16;
      background-color: transparent;
    }
  }

  &_almost-exceeded {
    .bp3-button {
      border-color: #fa8c16;
      color: #fff;
      background-color: #fa8c16;
      &:hover,
      &:focus {
        color: #fff;
        background-color: #fa8c16;
      }
    }
  }

  &_exceeded-spent {
    .bp3-button {
      border-color: #f5222d;
      background-color: #f5222d;
      color: #fff;
      &:hover,
      &:focus {
        border-color: #f5222d;
        background-color: #f5222d;
        color: #fff;
      }
    }
  }

  &_disabled {
    .bp3-button {
      cursor: not-allowed;
    }
  }
}
