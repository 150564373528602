.subtasks {
  $this: &;

  &__add-subtask-wrap {
    padding: 5px 13px;
    border: 1px solid var(--color-border);
    border-right: 0;
    border-left: 0;
  }

  &__add-subtask {
    &.ant-input {
      font-size: 14px;
      padding-left: 3px;
      padding-right: 3px;
      border-radius: 3px;
      resize: none;
      background-color: var(--color-background);
      color: var(--color-text-main);
      border: 1px solid transparent;
      transition-property: border-color;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }

      &:focus,
      &:active {
        box-shadow: none;
      }

      &::placeholder {
        transition-property: opacity;
        transition-duration: 0.2s;
      }

      &:focus {
        border-color: #40a9ff;
        &::placeholder {
          opacity: 0;
        }
      }
    }
  }

  &__header {
    margin-bottom: 4px;
  }

  &__estimation {
    font-size: 12px;

    &_hover {
      #{$this}__estimation-button {
        opacity: 0;
        pointer-events: none;
      }

      &:hover {
        #{$this}__estimation-button {
          opacity: 1;
          pointer-events: all;
        }
      }
    }

    &_tracking {
      #{$this}__estimation-current {
        color: var(--color-text-link);
      }
    }

    &_increased {
      #{$this}__estimation-end {
        color: #cf1322;
      }
    }
  }

  &__estimation-button {
    display: inline-block;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    outline: 0;
    transition: opacity 0.3s;
  }

  &__estimation-current,
  &__slash {
    color: #7f7f7f;
    line-height: 1;
    vertical-align: middle;
  }

  &__estimation-current {
    font-size: 13px;
    .time-tracker-control__current {
      display: none;
    }
  }

  &__estimation-end {
    color: var(--color-text-main);
    vertical-align: middle;
    font-size: 13px;
    &_popover {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
