.subtasks-subtask {
  $this: &;
  transition-property: background-color, padding-bottom;
  transition-duration: 0.3s;
  background-color: var(--color-background);
  .subtasks__estimation-current,
  .subtasks__slash {
    line-height: 1.1;
  }
  &:not(:last-child) {
    border-bottom: 1px solid var(--color-border);
  }

  &_is-blocker {
    background-color: var(--color-background-task-blocker);
  }

  &_is-done {
    background-color: var(--color-background-task-done);
  }

  &_description-opened {
    padding-bottom: 10px;
  }

  &__container {
    align-items: center;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    justify-content: space-between;
    padding: 7px 16px 7px 3px;
    &.is-upd {
      flex-wrap: wrap;

      #{$this}__center {
        order: 3;
        padding-left: 35px;
        padding-right: 35px;
      }

      #{$this}__details-button-wrap {
        min-width: 70px;
      }

      #{$this}__label {
        display: inline;
      }
    }

    &.is-checking {
      #{$this}__inner {
        display: inline-block;
      }

      #{$this}__label {
        display: inline-block;
        word-break: break-word;
      }
    }
  }

  &__col {
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }

  &__inner {
    margin-right: 5px;
    padding-right: 2px;
    padding-left: 2px;
    border: 1px solid transparent;
    border-radius: 2px;
    transition: border-color 0.3s;
    &:focus-within {
      outline: none;
      border-color: var(--color-text-link);
    }
    &:hover {
      #{$this}__details-button {
        width: auto;
      }
      #{$this}__details-button {
        position: relative;
        bottom: 2px;
        &_is-visible {
          bottom: 2px;
        }
      }
    }
  }

  &__details-button-wrap {
    position: relative;
    top: 2px;
    display: inline-block;
    line-height: 1;
  }

  &__drag-wrap {
    position: relative;
    top: 1px;
    line-height: 1;
  }

  &__drag {
    transform: rotate(90deg);
    font-size: 15px;
    color: var(--color-text-inactive);
  }

  &__bug-code {
    display: inline-block;
    color: var(--color-text-main);
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
  }

  &__checkbox {
    .ant-checkbox-inner {
      width: 12px;
      height: 12px;
    }
    &.ant-checkbox-wrapper {
      margin-right: 6px;

      .ant-checkbox-checked {
        &::after {
          border: 0;
        }

        .ant-checkbox-inner {
          background: transparent;
          border-color: transparent;

          &::after {
            width: 7px;
            height: 11px;
            border-color: #7cb305;
          }
        }
      }
    }
  }

  &__type-select {
    margin-right: 6px;
    color: var(--color-text-inactive);
    line-height: 1;
  }

  &__status-select {
    margin-right: 6px;
  }

  &__description {
    display: flex;
    flex-direction: column;
    min-height: 75px;
    margin: 0 33px;
    color: var(--color-text-muted);
    .ProseMirror {
      max-height: 265px;
      overflow: auto;
      flex-grow: 1;
      border: 1px solid transparent;
      padding: 5px;
      font-size: 12px;
      border-bottom: 0;
    }
    .editor-footer {
      border-color: transparent;
    }
  }

  &__details-button-text {
    color: var(--color-text-link);
    font-size: 12px;
    margin-right: 3px;
  }

  &__details-button {
    display: inline-flex;
    width: 12px;
    align-items: center;
    overflow: hidden;
    flex-shrink: 0;
    cursor: pointer;

    &_no-description {
      width: 0;
    }

    &_has-description {
      width: auto;
      position: relative;
      bottom: 2px;
    }

    &:hover,
    &_is-visible {
      width: auto;
    }

    &_is-visible {
      position: relative;
      bottom: 2px;
      #{$this}__chevron-icon {
        transform: rotate(180deg);
      }
    }
  }

  &__label {
    display: inline-block;
    color: var(--color-text-main);
    outline: none;
    min-width: 10px;
    margin-right: 5px;
  }

  &__center {
    width: 100%;
    font-size: 15px;
  }

  &__estimation-input {
    &.ant-input {
      display: inline-block;
      width: auto;
      max-width: 50px;
      padding: 0;
      line-height: 1;
      vertical-align: middle;
      &:focus {
        box-shadow: none;
      }
    }
  }
}
