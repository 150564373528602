.data-range-input {
  $this: &;

  .bp3-input-group {
    max-width: 96px;
    width: 100%;
    border-radius: 2px;

    input {
      color: var(--color-text-main);
    }

    &:first-child {
      max-width: 104px;
      position: relative;
      padding-right: 8px;
      margin-right: 2px;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 6px;
        height: 1px;
        background-color: var(--color-text-inactive);
      }
    }
  }

  &__icon {
    &.anticon {
      display: block;
      pointer-events: none;
    }
  }

  .bp3-input-group > .bp3-input-left-container,
  .bp3-input-group > .bp3-icon {
    z-index: 15;
  }

  .bp3-input-group .bp3-input-action,
  .bp3-input-group > .bp3-input-left-container,
  .bp3-input-group > .bp3-button,
  .bp3-input-group > .bp3-icon {
    top: 50%;
    left: 6px;
    transform: translateY(-50%);
  }

  .bp3-input {
    box-shadow: none;
    font-size: 12px;
    height: 26px;
    letter-spacing: -0.8px;
    border: 1px solid var(--color-border);
    transition: border-color 0.3s;
    padding-left: 22px !important;
    padding-right: 3px;
    line-height: 26px;

    &::placeholder {
      color: var(--color-text-inactive);
    }

    &:focus {
      border-color: var(--color-text-link);
    }
  }
}

.date-range-portal {
  .bp3-transition-container {
    z-index: 41;
  }
}
