.task-panel-comment {
  $this: &;
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
  margin-bottom: 10px;
  &:hover,
  &_has-popover {
    #{$this}__action-buttons {
      opacity: 1;
    }
  }

  &.not-editable {
    .ProseMirror,
    .prose-mirror-editor {
      border: none;
      padding: 0;

      p {
        word-break: break-word;
      }
    }
    .editor-footer {
      display: none;
    }
  }

  .prose-mirror-editor {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
  }

  .editor-footer {
    // right: 10px;
    // left: 5px;
    // bottom: 5px;
    // padding-left: 5px;
    // padding-right: 5px;
  }

  &__avatar {
    &.ant-avatar {
      margin-right: 8px;
    }
    flex-shrink: 0;
  }

  &__name {
    margin-bottom: 2px;
    font-size: 14px;
    color: var(--color-text-main);
    font-weight: 600;
    line-height: 1;
    letter-spacing: -0.3px;
  }

  &__date {
    font-size: 12px;
    color: var(--color-text-secondary);
    line-height: 1;
    letter-spacing: -0.4px;
    margin-right: 3px;
  }

  &__content {
    flex-grow: 1;
    font-size: 14px;
    color: var(--color-text-main);
  }

  &__content-link {
    color: var(--color-text-link);
  }

  &__header {
    position: relative;
    display: flex;
    align-items: flex-start;
    padding-right: 50px;
  }

  &__info {
    margin-bottom: 5px;
    margin-right: 10px;
    padding-right: 20px;
  }

  &__files {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 8px;
  }

  &__action-buttons {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    margin-left: auto;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 0.3s;
  }

  &__action-button {
    display: inline-block;
    background: none;
    border: none;
    outline: none;
    padding: 0;
    cursor: pointer;

    .comment-list-item-popover {
      &.bp3-popover {
        &.bp3-popover-content-sizing {
          width: 145px;
        }
      }
    }
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.task-comment-delete-confirmation-popover {
  h3 {
    color: var(--color-text-main);
    font-size: 16px;
  }
  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__button {
    &:not(:last-child) {
      margin-right: 8px;
    }
    &_yes {
      &.ant-btn {
        background-color: var(--color-background-popover);
        color: var(--color-text-link);
        border-color: var(--color-text-link);
        &:hover {
          color: #fff;
          background-color: var(--color-text-link);
        }
      }
    }
    &_no {
      &.ant-btn {
        background-color: var(--color-background-popover);
        color: var(--color-text-main);

        &:hover {
          color: var(--color-text-link);
        }
      }
    }
  }
}
